import React from "react";
import Footer from "src/components/frontend/Footer";
import Navbar from "src/components/frontend/Navbar";
import Title from "src/components/frontend/Title";
import StaticContent from "src/components/moleculs/StaticContent";

const PrivacyPolice = () => {
  return (
    <>
      <Navbar />
      <Title text="PRIVACY & COOKIE POLICY" />
      <StaticContent>
        <p>
          Deze “Privacy &amp; Cookie Policy” regelt de verwerking van uw
          persoonsgegevens door de verantwoordelijke voor de verwerking:
          Rij-Huis, Varendreef 95, 8750 Wingene (hierna: Rij-Huis)
        </p>
        <p>
          Lees deze Privacy Policy aandachtig. Hij bevat essentiële informatie
          over hoe uw persoonsgegevens verwerkt worden en welke cookies gebruikt
          worden. Door uw persoonsgegevens te delen op onze Website
          www.platform-rijschoollesgever.be door ons te contacteren per
          e-mail/per telefoon of door het overmaken van een vraag/klacht,
          verklaart u kennis te hebben genomen van deze Privacy Policy en gaat u
          uitdrukkelijk akkoord met de inhoud.
        </p>
        <h2>
          <strong>Inhoudsopgave</strong>
        </h2>
        <p>Artikel   1 – Welke gegevens verwerken we over u?</p>
        <p>Artikel   2 – Waarvoor gebruiken we uw persoonsgegevens?</p>
        <p>Artikel   3 – Wie ontvangt uw persoonsgegevens?</p>
        <p>Artikel   4 – Hoelang bewaren we uw persoonsgegevens?</p>
        <p>Artikel   5 – Uw rechten</p>
        <p>Artikel   6 – Hoe beveiligen we uw persoonsgegevens?</p>
        <p>Artikel   7 – Cookies</p>
        <h2>
          <strong>Artikel 1 </strong>{" "}
          <strong>Welke gegevens verwerken we over u?</strong>
        </h2>
        <ol>
          <li>
            Rij-Huis verwerkt in het kader van haar werkzaamheden de volgende
            persoonsgegevens van u:
          </li>
        </ol>
        <p>
          −         <strong>Cookies </strong>- Bij ieder gebruik van onze
          website of app kunnen op basis van cookies en gelijkaardige
          technologieën verschillende persoonsgegevens over u verzameld worden,
          met name uw IP-adres, surfgedrag, apparaattype, locatie, de datum en
          duur van het gebruik van website of app, besturingssysteem, .... (Meer
          informatie over de manier waarop wij cookies gebruiken, vindt u in
          artikel 7);
        </p>
        <p>
          −         <strong>Klantgegevens</strong> - Als u gebruik maakt van
          onze website of app of een bestelling plaatst, kunnen we de volgende
          klantinformatie over u verzamelen: uw naam, voornaam, adresgegevens,
          e-mailadres, telefoonnummer, de naam van uw bedrijf en BTW-nummer,
          geboortedatum, geslacht, taal, bankrekeningnummer, ... en eventuele
          andere gegevens die u ons overmaakt;
        </p>
        <p>
          −         <strong>Leveranciersgegevens </strong>- Als u een
          (potentiële) leverancier bent, verwerken wij de volgende
          persoonsgegevens van u: uw naam, voornaam, adresgegevens, e-mailadres,
          telefoongegevens, de naam van uw bedrijf en BTW-nummer,
          bankrekeningnummer, en eventuele andere gegevens die u ons overmaakt;
        </p>
        <p>
          −         <strong>Communicatiegegevens</strong> - Als u ons belt,
          mailt of een contactformulier indient, dan kunnen we de volgende
          persoonsgegevens verzamelen: uw naam, e-mailadres, telefoonnummer,
          evenals de gegevens die u zelf vrijgeeft;
        </p>
        <p>&nbsp;</p>
        <h2>
          <strong>Artikel 2 </strong>{" "}
          <strong>Waarvoor gebruiken we uw persoonsgegevens?</strong>
        </h2>
        <ol>
          <li>Algemene doeleinden:</li>
        </ol>
        <p>         Wij gebruiken uw persoonsgegevens uitsluitend als volgt:</p>
        <p>
          -        <strong>Cookies</strong> - om onze website en app te
          onderhouden en verbeteren en om persoonsgegevens op te nemen in
          statistieken, met als rechtsgrond uw expliciete, voorafgaande
          toestemming;
        </p>
        <p>
          -        <strong>Klantgegevens</strong> - om uw account op onze
          website of app te beheren en voor de levering en facturatie van door u
          bestelde producten of diensten, met als rechtsgrond de uitvoering van
          een overeenkomst met u of omdat wij daartoe een gerechtvaardigd belang
          hebben;
        </p>
        <p>
          -        <strong>Leveranciersgegevens </strong>-  om u een
          offerte-aanvraag toe te sturen, een bestelling te plaatsen, uw
          facturen te betalen en met u te communiceren, met als rechtsgrond de
          uitvoering van een overeenkomst met u;
        </p>
        <p>
          -        <strong>Communicatiegegevens </strong>- om uw vraag of
          verzoek te kunnen behandelen, met als rechtsgrond uw expliciete,
          voorafgaande toestemming online en ons gerechtvaardigd belang bij
          telefonisch contact;
        </p>
        <ol start={2}>
          <li>Direct marketing:</li>
        </ol>
        <p>
          We gebruiken uw persoonsgegevens ook voor direct marketing,
          nieuwsbrieven, acties en promoties, met als rechtsgrond ons
          gerechtvaardigd belang ten aanzien van bestaande klanten of personen
          die interesse getoond hebben in onze diensten, dan wel uw expliciete,
          voorafgaande toestemming in alle andere gevallen.
        </p>
        <p>
          Als u dergelijke communicatie niet langer wenst te ontvangen en wij de
          verwerking van uw persoonsgegevens voor onze direct marketing
          doeleinden stopzetten, dan kan dat zonder kost en zonder dat u
          daarvoor een reden moet geven, door te klikken op de link in elk van
          onze direct mailings of door ons rechtstreeks te contacteren per mail
          of telefoon.
        </p>
        <h2>
          <strong>Artikel 3 </strong>{" "}
          <strong>Wie ontvangt uw persoonsgegevens?</strong>
        </h2>
        <ol>
          <li>
            In bepaalde omstandigheden moeten wij uw gegevens delen met derden:
          </li>
        </ol>
        <p>
          -       overheidsinstanties, gerecht en politiediensten op basis van
          een gerechtelijk bevel of om te voldoen aan andere dwingende wet- of
          regelgeving,
        </p>
        <p>-        financiële instellingen,</p>
        <p>-        verzekeringsinstellingen,</p>
        <ol start={2}>
          <li>
            Als wij onze activiteiten ooit stopzetten of overdragen aan iemand
            anders, bijvoorbeeld omdat we failliet gaan of onze activiteiten
            verkopen, dan kan dat betekenen dat uw persoonsgegevens aan derden,
            die geheel of gedeeltelijk onze activiteiten gaan overnemen, worden
            overgedragen. Wij zullen u in dat geval steeds in de mate van het
            mogelijke vooraf informeren, maar u begrijpt dat dit niet onder alle
            omstandigheden technisch of commercieel haalbaar is.
          </li>
        </ol>
        <h2>
          <strong>Artikel 4 </strong>{" "}
          <strong>Hoelang bewaren we uw persoonsgegevens?</strong>
        </h2>
        <p>
          Wij bewaren uw persoonsgegevens zolang dit noodzakelijk is in functie
          van de doeleinden van de verwerking en de wettelijke voorschriften:
        </p>
        <ul>
          <li>Facturen: bewaartermijn maximum 7 jaar</li>
          <li>Contactgegevens: bewaartermijn maximum 5 jaar</li>
        </ul>
        <h2>
          <strong>Artikel 5 </strong> <strong>Uw rechten</strong>
        </h2>
        <p>
          U heeft het recht om op elk moment gratis kennis te nemen van uw
          persoonsgegevens en van het gebruik dat wij van die persoonsgegevens
          maken. U heeft het recht om ons te verzoeken uw persoonsgegevens te
          verbeteren, aan te vullen of te verwijderen. U mag in een aantal
          gevallen opgesomd in de GDPR ook vragen om de verwerking van uw
          persoonsgegevens te beperken. U heeft het recht om zich te verzetten
          tegen de verwerking van uw persoonsgegevens als u daar ernstige en
          legitieme redenen voor hebt die onze noodzaak om je gegevens te
           verwerken overstijgen. U heeft ook steeds het recht om zich te
          verzetten tegen het gebruik van persoonsgegevens voor doeleinden van
          direct marketing, zelfs zonder daarvoor een reden te moeten aangeven.
          U beschikt over het recht om uw persoonsgegevens in digitale en
          leesbare vorm op te vragen en/of aan andere verantwoordelijken over te
          laten dragen aan een andere dienstverlener van uw keuze.  Voor zover
          onze verwerking gebaseerd is op uw voorafgaande toestemming, heeft u
          het recht om die toestemming in te trekken.
        </p>
        <p>
          U kan uw rechten uitoefenen per e-mail naar
          karin@platform-rijschoollesgever.be per post naar Rij-Huis, Varendreef
          95, 8750 Wingene
        </p>
        <p>
          U beschikt over het recht om een klacht in te dienen bij de Belgische
          Gegevensbeschermingsautoriteit:
        </p>
        <p>&nbsp;</p>
        <p>Drukpersstraat 35</p>
        <p>1000 Brussel</p>
        <p>Tel : +32 (0)2 274 48 00</p>
        <p>contact@apd-gba.be.</p>
        <p>&nbsp;</p>
        <p>
          U kan desgevallend ook terecht voor de burgerlijke rechtbank voor het
          vorderen van schadevergoeding.
        </p>
        <h2>
          <strong>Artikel 6 </strong>{" "}
          <strong>Hoe beveiligen we uw persoonsgegevens?</strong>
        </h2>
        <p>
          Wij hebben de gepaste veiligheidsmaatregelen genomen op technisch en
          organisatorisch vlak om de veiligheid van uw persoonsgegevens te
          garanderen: HTTPS en SSL certificaten, toegangsbeheer tot de back-end,
          DPIA, privacy by design, …
        </p>
        <p>
          In geen geval kan Rij-Huis aansprakelijk worden geacht voor enige
          directe of indirecte schade die voortvloeit uit een foutief of
          onrechtmatig gebruik door een derde van de persoonsgegevens.
        </p>
        <h2>
          <strong>Artikel 7 </strong> <strong>Cookies</strong>
        </h2>
        <p>
          Onze website gebruikt cookies en gelijkaardige technologieën. Dit
          helpt ons om u een betere gebruikerservaring te bieden wanneer u onze
          website bezoekt en laat ons ook toe onze website te optimaliseren.
          Zonder uw voorafgaande toestemming plaatsen wij uitsluitend strikt
          noodzakelijke cookies. Voor alle andere cookies vragen wij eerst uw
          toestemming.
        </p>
        <p>
          Onderstaande cookielijst geeft u een overzicht van de cookies die onze
          website gebruikt.
        </p>
        <p>
          <strong>Cookielijst</strong>
        </p>
        <p>
          Een cookie is een klein tekstbestandje met gegevens dat een website
          (wanneer een gebruiker deze bezoekt) op uw apparaat wil opslaan om
          zodoende informatie over u, zoals uw taalvoorkeur of inloginformatie,
          te kunnen herinneren. Deze cookies worden door ons ingesteld en heten
          interne cookies. Daarnaast gebruiken we externe cookies, namelijk
          cookies van een ander domein dan waar u zich bevindt. We gebruiken de
          cookies en andere tracking-technologieën voor de volgende doeleinden:
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Strikt noodzakelijke cookies</strong>
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          Deze cookies slaan geen persoonlijk identificeerbare informatie op,
          maar zijn nodig om onze website te laten werken en kunnen om die reden
          niet uitgeschakeld worden. In de meeste gevallen worden deze cookies
          alleen gebruikt wanneer u een dienst aanvraagt, bijvoorbeeld uw
          privacyinstellingen registreren, in onze website inloggen of een
          formulier invullen. U kunt uw browser instellen om deze cookies te
          blokkeren of om u voor deze cookies te waarschuwen, maar sommige delen
          van onze website zullen dan niet werken.<strong> </strong>
        </p>
        <p>
          <strong>Analytische cookies</strong>
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          Deze cookies stellen ons in staat bezoekers en hun herkomst te tellen,
          zodat we de prestatie van onze website kunnen analyseren en
          verbeteren. Ze helpen ons te begrijpen welke pagina’s het meest en
          minst populair zijn en hoe bezoekers zich door de gehele site bewegen.
          Alle informatie die deze cookies verzamelen, wordt geaggregeerd en is
          daarom anoniem. Als u deze cookies niet toestaat, weten wij niet
          wanneer u onze site bezocht heeft.
        </p>
        <p>
          <strong>Functionele cookies</strong>
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          Deze cookies stellen onze website in staat om extra functies en
          persoonlijke instellingen aan te bieden. Ze kunnen ingesteld worden
          door ons of door externe aanbieders van diensten die we op onze
          pagina’s hebben geplaatst. Als u deze cookies niet toestaat, kunnen
          deze of sommige van deze diensten wellicht niet correct werken.
        </p>
        <p>
          Als u vaststelt dat andere cookies via onze website geplaatst worden,
          danken wij u ons daarvan onmiddellijk te verwittigen, zodat wij de
          nodige aanpassingen kunnen doen.
        </p>
        <p>
          We geven u zo veel mogelijk informatie over cookies geplaatst door
          derde partijen. Als u toch meer wil weten, verwijzen we u graag naar
          de privacyverklaringen van deze partijen op hun respectieve websites.
          Let op: wij oefenen geen enkele invloed uit op de inhoud van die
          verklaringen, noch op de inhoud van de cookies van deze derde
          partijen.
        </p>
        <p>
          Bij uw eerste bezoek aan onze website wordt u gevraagd om onze cookies
          te aanvaarden. U kan nadien uw keuzes op elk ogenblik zelf beheren.
        </p>
        <p>
          Als u verder nog vragen of opmerkingen heeft over de verwerking van uw
          persoonsgegevens, contacteer ons dan per e-mail op{" "}
          <a href="mailto:karin@platform-rijschoollesgever.be">
            <u>karin@platform-rijschoollesgever.be</u>
          </a>
           per post op Rij-Huis, Varendreef 95, 8750 Wingene
        </p>
        <p>
          Meer informatie over cookies vindt u ook op:
          <a href="http://www.allaboutcookies.org/"> </a>
          <a href="http://www.allaboutcookies.org/">
            <u>http://www.allaboutcookies.org/</u>
          </a>
          .
        </p>
        <p>&nbsp;</p>
        <p>
          Meer informatie over online behavioural advertising en online privacy
          vindt u hier:<a href="http://www.youronlinechoices.eu/"> </a>
          <a href="http://www.youronlinechoices.eu/">
            <u>http://www.youronlinechoices.eu/</u>
          </a>
        </p>
      </StaticContent>
      <Footer />
    </>
  );
};

export default PrivacyPolice;
