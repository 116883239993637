import { createTheme } from "@mui/material";

const Theme = createTheme({
  palette: {
    primary: {
      light: "#E4E6F5",
      main: "#2946D6",
      dark: "#152441",
    },
    secondary: {
      main: "#f44336",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media(min-width: 1020px)": {
            maxWidth: "1288px",
            paddingInline: "45px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "100px",
          padding: "16px 28px",
          lineHeight: 1,
          fontSize: "18",
          fontWeight: "400",
          textTransform: "none",
          letterSpacing: 0,
          fontFamily: "Metropolis-Regular",
          "&:hover": {
            boxShadow: "0 0 10px #2946d6a3",
            backgroundColor: "#1d3299",
          },
        },
        text: {
          color: "#000000",
          fontFamily: "Metropolis-Regular",
          "&:hover": {
            boxShadow: "0 0 10px #2946d6a3",
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "p",
          body2: "p",
          inherit: "p",
        },
      },
      styleOverrides: {
        h1: {
          fontSize: "40px",
          fontFamily: "Metropolis-Bold",
          fontWeight: "bold",
          letterSpacing: 0,
          lineHeight: "1",
        },
        h2: {
          fontSize: "35px",
          fontFamily: "Metropolis-Bold",
          fontWeight: "bold",
          letterSpacing: 0,
          lineHeight: "1",
        },
        h3: {
          fontSize: "33px",
          fontFamily: "Metropolis-Bold",
          fontWeight: "bold",
          letterSpacing: 0,
          lineHeight: "1",
          "@media(max-width: 1440px)": {
            fontSize: "26px",
          },
          "@media(max-width: 1281px)": {
            fontSize: "22px",
          },
          "@media(max-width: 600px)": {
            fontSize: "24px",
          },
        },
        h4: {
          fontSize: "18px",
          fontFamily: "Metropolis-Bold",
          fontWeight: "bold",
          letterSpacing: 0,
          lineHeight: "1",
        },
        h5: {
          fontSize: "16px",
          fontFamily: "Metropolis-Bold",
          fontWeight: "bold",
          letterSpacing: 0,
          lineHeight: "auto",
        },
        body1: {
          fontSize: "16px",
          fontFamily: "Metropolis-Regular",
          fontWeight: "normal",
          letterSpacing: 0,
          lineHeight: "28px",
        },
        body2: {
          fontSize: "14px",
          fontFamily: "Metropolis-Medium",
          fontWeight: "medium",
          letterSpacing: 0,
          lineHeight: "1",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ".MuiInputLabel-root": {
            fontFamily: "Metropolis-regular",
            fontSize: "16px",
            lineHeight: "1",
            paddingRight: "10px",
          },
          ".MuiInputBase-root": {
            borderRadius: "14px",
            fontFamily: "Metropolis-medium",
            fontSize: "16px",
            lineHeight: "1",
            ":hover": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#2946D6",
              },
            },
          },
          ".MuiInputBase-input": {
            height: "16px",
            padding: "16px 20px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {},
        select: {
          paddingBottom: "10px",
          paddingTop: "16px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          ".MuiInputLabel-root": {
            fontFamily: "Metropolis-regular",
            fontSize: "16px",
            lineHeight: "1",
            paddingRight: "10px",
          },
          ".MuiOutlinedInput-root": {
            borderRadius: "14px",
            fontFamily: "Metropolis-medium",
            fontSize: "16px",
            lineHeight: "1",
          },
          ":hover": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#2946D6",
            },
          },
          ".MuiInputBase-input": {
            height: "16px",
            padding: "14px 20px",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Metropolis-regular",
          ".MuiFormControlLabel-label": {
            fontSize: "14px",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 3px 6px #00000019",
          padding: "32px 52px",
          borderRadius: "20px",
          "@media(max-width: 1440px)": {
            padding: "16px 24px",
          },
          "@media(max-width: 600px)": {
            padding: "16px 12px",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingInline: "8px",
          paddingBlock: "20px",
          "&:hover": {
            backgroundColor: "inherit",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: "Metropolis-Medium",
          fontSize: "16px",
        },
        secondary: {
          fontFamily: "Metropolis-Medium",
          fontSize: "15px",
          color: "#000000",
          "&:hover": {
            color: "#2946D6",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          alignSelf: "flex-start",
          paddingBlock: "1px",
        },
      },
    },
  },
});

export default Theme;
