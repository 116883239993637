import { useState, useEffect } from "react";
import SwalCatch from "src/utils/helpers/SwalCatch";
import axios from "axios";
import { questionSidebar } from "src/services/question";
import SidebarSlide from "src/components/moleculs/Sidebar/SidebarSlide";
import Loading from "src/components/atoms/Loading";

export default function Faq() {
  const [render, rerender] = useState(false);
  const [faqMenus, setFaqMenus] = useState([]);

  useEffect(() => {
    axios
      .get("/api/questions-sidebar")
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          const errors = res.data?.errors;
          SwalCatch(errors, true);
        }
        const newData = res.data;
        setFaqMenus(newData);
        rerender(true);
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors;
        SwalCatch(errors, true);
      });

    return () => {
      rerender(false);
    };
  }, []);

  const hierarkiData = questionSidebar(faqMenus);

  return !render ? (
    <Loading />
  ) : (
    <>
      <SidebarSlide isSm data={hierarkiData} uri="oefenvragen" />
      {/* <FaqComponent data={hierarkiData} /> */}
    </>
  );
}
