import Footer from "src/components/frontend/Footer/Footer";
import Navbar from "src/components/frontend/Navbar/Navbar";
import Title from "src/components/frontend/Title/Title";
import Single from "src/components/frontend/Vacatures/Single";

const VacatureSingle = () => {
  return (
    <>
      <Navbar />
      <Title text="Vacature" />
      <Single />
      <Footer />
    </>
  );
};

export default VacatureSingle;
