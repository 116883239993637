import { ChevronRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml";
import c from "./AccordionListStyle";

/**
 * @typedef {object} AccordionListProps
 * @property {string} text
 * @property {string} [desc]
 * @property {string} state
 * @property {string} id
 * @property {object} [sxs]
 * @property {() => void} onClick
 *
 * @param {AccordionListProps} param1
 */

const AccordionList = ({ text, sxs, onClick, state, id, desc }) => {
  return (
    <Box sx={{ ...c.wrapper, ...sxs }}>
      <Accordion sx={c.accordion} expanded={state === id} onChange={onClick}>
        <AccordionSummary sx={c.summary}>
          <Typography variant="h4" sx={{ ...c.text, ...sxs?.text }}>
            {text}
          </Typography>
          <ChevronRight
            sx={{
              ...c.icon,
              ...sxs?.icon,
              ...(state === id ? c.iconDown : {}),
            }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"div"} variant="body1" sx={c.desc}>
            <SetInnerHtml sxs={{ paddingLeft: 0 }}>{desc}</SetInnerHtml>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AccordionList;
