import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { Link } from "react-router-dom";
import useForm from "src/hooks/useForm";
import useMailto from "src/hooks/useMailto";
import c from "./ViaSyntraStyle";

const ViaSyntra = () => {
  const formRef = useRef(null);
  const { payload, fnOnChange, valid, fnOnInvalid, message, fnIsValid } =
    useForm({
      name: "",
      firstname: "",
      email: "",
      aggrement: false,
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    const bodyEmail = {
      ["Naam"]: payload.name,
      ["Voornaam"]: payload.firstname,
      ["E-mail"]: payload.email,
    };

    if (fnIsValid(formRef)) {
      useMailto("karin@platform-rijschoollesgever.be", "ViaSyntra", bodyEmail);
    }
  };

  return (
    <Box sx={c.wrapper}>
      <Typography variant="body1">
        Je ontvangt een <b>registratiecode</b> van jouw docent. Met deze code
        kan je een account aanmaken. Zo krijg je toegang tot alle
        studiemateriaal voor de <b>opleiding rijinstructeur.</b>
      </Typography>

      <Box
        sx={c.fields}
        component="form"
        onSubmit={handleSubmit}
        ref={formRef}
        noValidate
      >
        <TextField
          sx={c.field}
          id="name"
          label="Naam"
          variant="outlined"
          name="name"
          value={payload.name}
          onChange={fnOnChange("name")}
          onInvalid={fnOnInvalid("name", "Het naam moet ingevuld zijn!")}
          required
          // @ts-ignore
          error={valid.name}
          helperText={message.name}
        />
        <TextField
          sx={c.field}
          id="firstname"
          label="Voornaam"
          variant="outlined"
          name="firstname"
          value={payload.firstname}
          onChange={fnOnChange("firstname")}
          onInvalid={fnOnInvalid(
            "firstname",
            "Het voornaam moet ingevuld zijn!"
          )}
          required
          // @ts-ignore
          error={valid.firstname}
          helperText={message.firstname}
        />
        <TextField
          sx={c.field}
          id="email"
          label="E-mail"
          variant="outlined"
          name="email"
          value={payload.email}
          onChange={fnOnChange("email")}
          onInvalid={fnOnInvalid("email", "Het e-mail moet ingevuld zijn!")}
          required
          // @ts-ignore
          error={valid.email}
          helperText={message.email}
        />
        <FormControlLabel
          sx={c.field}
          control={
            <Checkbox
              defaultChecked={false}
              onChange={fnOnChange("aggrement", true)}
            />
          }
          label="Jullie mogen mijn gegevens bijhouden voor verdere communicatie *"
        />

        <Button sx={c.button} type="submit" variant="contained">
          Wachtwoord aanvragen
        </Button>
      </Box>
    </Box>
  );
};

export default ViaSyntra;
