import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Home
 * @property {object} [sxs]
 *
 * @param {Home} param1
 */

export default function IcClose({ sxs }) {
  return (
    <SvgIcon sx={sxs}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="12" fill="#D8DBDE" />
        <path
          d="M12.0001 19.9995L20.0001 11.9995"
          stroke="#44474A"
          strokeWidth="1.2"
        />
        <path d="M20 20L12 12" stroke="#44474A" strokeWidth="1.2" />
      </svg>
    </SvgIcon>
  );
}
