import { Box, Card } from "@mui/material";
import c from "./SwipperStyle";
import TitleMain from "src/components/atoms/TitleMain";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import useSwiperRef from "src/hooks/useSwiperRef";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  FitScreen,
} from "@mui/icons-material";

/**
 *
 * @typedef {object} Swipper
 * @property {() => void} handlePopupProps
 * @property {(param) => void} [onSlideChange]
 * @property {any} [content]
 * @property {any} [footer]
 * @property {string} [title]
 * @property {boolean} [iconToggle]
 * @property {boolean} [isScrollbar]
 * @property {boolean} [isFooterSlide]
 * @property {boolean} [isKeyboardArrow]
 * @property {boolean} [allowTouchMove]
 * @property {boolean} [isNav]
 * @property {array} [datas=[]]
 * @property {number} [initialSlide=0]
 * @property {any} [ref]
 *
 * @param {Swipper} param0
 */

const Swipper = ({
  handlePopupProps,
  iconToggle,
  isScrollbar,
  initialSlide,
  onSlideChange,
  title,
  content,
  datas,
  footer: Footer,
  allowTouchMove = true,
  isNav = true,
  isFooterSlide = false,
  isKeyboardArrow = true,
}) => {
  const [paginationEl, paginationRef] = useSwiperRef();
  const [scrollbarEl, scrollbarRef] = useSwiperRef();
  const [nextEl, nextRef] = useSwiperRef();
  const [prevEl, prevRef] = useSwiperRef();

  const handlePopup = () => {
    handlePopupProps();
  };

  const navigateProps = (isScrollbar) => {
    if (isScrollbar) {
      return {
        scrollbar: {
          el: scrollbarEl,
          draggable: true,
        },
      };
    } else {
      return {
        pagination: {
          dynamicBullets: true,
          el: paginationEl,
        },
      };
    }
  };

  return (
    <Box sx={c.wrapper} className="swipperWrapper">
      <Card>
        <Box sx={c.popup} onClick={handlePopup}>
          {iconToggle ? <Close /> : <FitScreen />}
        </Box>
        {title !== null && (
          <TitleMain
            text={title}
            sxs={{ h2: { textTransform: "capitalize" } }}
          />
        )}
        <Swiper
          navigation={{
            nextEl: nextEl,
            prevEl: prevEl,
          }}
          keyboard={{
            enabled: isKeyboardArrow,
          }}
          mousewheel={{
            forceToAxis: true,
          }}
          modules={[Keyboard, Pagination, Navigation, Scrollbar, Mousewheel]}
          className="mySwiper"
          initialSlide={initialSlide}
          slideToClickedSlide={true}
          allowTouchMove={allowTouchMove}
          onSlideChange={(prev) => {
            onSlideChange(prev.activeIndex);
          }}
          {...navigateProps(isScrollbar)}
        >
          {datas?.length > 0 ? (
            datas?.map((_, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="slide-0016">
                    <Box sx={c.wraperSliderItem}>{content(_, i)}</Box>
                    {isFooterSlide && <Footer />}
                  </div>
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide>
              <Box sx={c.wraperSliderItem}></Box>
            </SwiperSlide>
          )}
        </Swiper>

        <div ref={prevRef} className="swiperPrev">
          {isNav && <ChevronLeft />}
        </div>
        <div ref={nextRef} className="swiperNext">
          {isNav && <ChevronRight />}
        </div>
        {!isFooterSlide && <Footer />}
      </Card>
      <div className={"scrollbar"} ref={scrollbarRef}></div>
      <div className={iconToggle ? "white" : ""} ref={paginationRef}></div>
    </Box>
  );
};

export default Swipper;
