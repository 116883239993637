import WrapperContent from "src/components/moleculs/WrapperContent/WrapperContent";
import Footer from "./Footer";
import Intro from "./Intro";
import Items from "./Items";

const Dashboard = () => {
  return (
    <WrapperContent isFooter={false}>
      <Intro />
      <Items />
      <Footer />
    </WrapperContent>
  );
};

export default Dashboard;
