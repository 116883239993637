import { Box } from "@mui/material";

/**
 *
 * @typedef {object} ExternalLink
 * @param {{ children: any, href: string, [key: string]: any}} param0
 *
 * @returns
 */
const ExternalLink = ({ children, href, ...props }) => {
  return (
    <Box component="a" href={href} {...props}>
      {children}
    </Box>
  );
};

export default ExternalLink;
