import useStore from "./useStore";

const useRole = () => {
  const store = useStore();
  const dataUser = store.profileAccount.data;

  let userRole = 0;
  if (dataUser !== null) {
    // @ts-ignore
    userRole = dataUser.role ?? 0;
  }

  return userRole;
};

export default useRole;
