import { Box, Divider, Typography } from "@mui/material";
import SocialMedia from "src/components/atoms/SocialMedia/SocialMedia";
import FooterBox from "./FooterBox";
import c from "./FooterStyle";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box sx={c.wrapper}>
      <Box sx={c.container}>
        <Box sx={c.items}>
          <FooterBox variant="logo" src="/images/logo-footer.png" />
          <FooterBox
            variant="full"
            title="Contact"
            subtitle="Karin Verleysen"
            text={`<a href="mailto:karin@platform-rijschoollesgever.be">karin@platform-rijschoollesgever.be</a>\n<a href="tel:+32472809544">+32472809544</a>`}
          />
          <FooterBox
            variant="nosubtitle"
            title="Rijscholen"
            text={`Bekijk <a href="/#contact">hier</a> alle deelnemende rijscholen \nRegistreer <a href="mailto:karin@platform-rijschoollesgever.be?subject=Inschrijven als rijschool">hier</a> jouw rijschool`}
          />
          <FooterBox variant="icon" title="Volg ons op">
            <SocialMedia />
          </FooterBox>
          <FooterBox variant="fullwidth" title="Syntra" />
          <FooterBox
            variant="special"
            title="Syntra"
            subtitle="Syntra West"
            text={`<a target="_blank" href="https://www.google.com/maps/place/Syntra+West+Ten+Briele/@51.187294,3.2253117,17z/data=!3m1!4b1!4m5!3m4!1s0x47c350f20dfc2a8b:0x19131994accefcac!8m2!3d51.187294!4d3.2275004">Campus Ten Briele \nTen Briele 7, 8200 Sint-Michiels </a>\n<a href="mailto:heidi.vuylsteke@syntrawest.be">heidi.vuylsteke@syntrawest.be</a> \n<a href="tel:+32472809544">+32472809544</a>`}
          />
          <FooterBox
            variant="notitle"
            subtitle="Syntra Campus Mechelen"
            text={`<a target="_blank" href="https://www.google.com/maps/place/Syntra+AB+Mechelen+Campus/@51.0511621,4.4575794,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3e59dfb566e01:0x31da3bea9c0907c3!8m2!3d51.0511621!4d4.4597681">Syntra Mechelen \nOude Baan 2, 2800 Mechelen </a> \n<a href="mailto:mechelen@syntra-ab.be">mechelen@syntra-ab.be</a>\n<a href="tel:078159999">078 15 99 99</a>`}
          />
          <FooterBox
            variant="notitle"
            subtitle="Syntra Campus Gent"
            text={`<a target="_blank" href="https://www.google.com/maps/place/Syntra/@51.025839,3.6603698,17z/data=!3m1!4b1!4m5!3m4!1s0x47c3723d4480bf81:0x2e860f0fbf157977!8m2!3d51.0262938!4d3.66258">Syntra Gent \nAutoweg-Zuid 39051 Gent (Afsnee) </a>\n<a href="mailto:tine.van.langenhove@syntra-mvl.be">tine.van.langenhove@syntra-mvl.be</a> \n<a href="tel:03 760 08 36">03 760 08 36</a>`}
          />
          <FooterBox
            variant="notitle"
            subtitle="Syntra A-B"
            text={`<a target="_blank" href="https://www.google.com/maps/place/Syntra+AB+campus+Leuven/@50.8495011,4.7241676,15z/data=!4m2!3m1!1s0x0:0x2bb75487ed01a6c3?sa=X&ved=2ahUKEwj5jYKvu5D4AhVG7XMBHe7UA4kQ_BJ6BAhSEAU">Syntra Campus Leuven \nGeldenaaksebaan 327, 3001 Heverlee</a> \n<a href="mailto:helena.verboomen@syntra-ab.be">helena.verboomen@syntra-ab.be</a> \n<a href="mailto:heidi.mylle@syntra-ab.be">heidi.mylle@syntra-ab.be</a>`}
          />
          <FooterBox
            variant="notitle"
            subtitle="Syntra PXL Campus Hasselt"
            text={`<a target="_blank" href="https://www.google.com/maps/place/SyntraPXL+Campus+Hasselt/@50.9372015,5.3611542,17z/data=!4m5!3m4!1s0x47c121733262c097:0x6d1c86199c997564!8m2!3d50.9372015!4d5.3633429">Syntra Campus Hasselt \nTrichterheideweg 7, 3500 Hasselt</a> \n<a href="mailto:rudi.ponthier@syntrapxl.be">rudi.ponthier@syntrapxl.be</a> \n<a href="tel:011 30 32 08">011 30 32 08</a>`}
          />
        </Box>
        <Divider sx={c.divider} />
        <Box sx={c.bottom}>
          <Typography variant="body2">
            © 2022 Platform Rijschoollesgever{" "}
          </Typography>
          <Typography variant="body2" sx={c.privacy_policy}>
            <Link to="/privacy-policy">Privacy policy</Link>
          </Typography>
          <Typography variant="body2">
            This platform was Upproved by{" "}
            <a
              href="https://www.salesup.be/"
              target={"_blank"}
              style={{ textDecoration: "underline" }}
            >
              salesUp
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
