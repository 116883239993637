import { Link } from "react-router-dom";
import c from "./CategoryItemStyle";
import g from "src/utils/MuiStyle/Global";
import { Button } from "@mui/material";

/**
 * @typedef {object} TCategoryItem
 * @property {string} name
 * @property {boolean} isActive
 * @property {() => void} [onClick]
 *
 * @param {TCategoryItem} param0
 * @returns
 */
const CategoryItem = ({ name, onClick, isActive }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{ ...(isActive ? {} : g.button.secondary), ...c.button }}
    >
      {name}
    </Button>
  );
};

export default CategoryItem;
