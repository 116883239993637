import { Box, Button, Container, Typography } from "@mui/material";
import PriceListCard from "src/components/moleculs/PriceListCard";
import c from "./PrijsStyle";
import { Helmet } from "react-helmet-async";
import g from "src/utils/MuiStyle/Global";

const dummyData = [
  {
    numberList: "1",
    titleList: "Cursist",
    subTitleList: "Rijinstructeur worden via belevingstraject",
    descriptionList:
      "Rijinstructeur worden met één been op de werkvloer? Dan is de opleiding rijschoollesgever via belevingstraject echt iets voor jou. Wat de troeven zijn van het belevingstraject kan je ontdekken in de video",
    ytList: "https://www.youtube.com/watch?v=hj9v16i4iMc",
    data: [
      {
        heading: "Syntra",
        title: "Opleiding rijschoollesgever",
        content:
          "Je ontvangt een registratie- code tijdens de eerste lesweek. Hiermee krijg je gratis toegang tot alle lesmaterialen.",
        footer: "GRATIS",
        isButton: false,
        buttonName: "Wachtwoord aanvragen",
        buttonLink: "/register/#via-syntra",
      },
      {
        heading: "Via rijscholen",
        title: "Belevingstraject workshops + praatcafé's",
        content: "Toegang tot alle lesmateriaal (inschrijving via platform)",
        footer: "€700/jaar",
        isButton: false,
        buttonName: "Aankopen",
        buttonLink: "https://buy.stripe.com/aEUdTvgzE2RD2n6aEJ",
      },
      {
        title: "Bekijk hier alle deelnemende rijscholen",
        isButton: false,
        buttonLink: "/#contact",
        buttonName: "Bekijk rijscholen",
      },
    ],
  },
  {
    numberList: "2",
    titleList: "Zelfstudie",
    subTitleList: "Rijinstructeur worden via zelfstudie",
    descriptionList:
      "Studeer op jouw eigen tempo voor het schriftelijk en mondeling examen, dat georganiseerd wordt door de overheid. Vooreerst wordt het cursusmateriaal gratis aangeboden, hoe dit kan en hoe je nog extra ondersteuning kan krijgen in het behalen van je brevet verneem je in de volgende video",
    ytList: "https://www.youtube.com/watch?v=5IlpkNLX-Y0",
    data: [
      {
        heading: "Basis pakket",
        title: "Examenleerstof",
        content: "Verkeersreglementering \nverkeerswetgeving \nAutomechanica",
        footer: "GRATIS",
        isButton: false,
        buttonLink: "/register/#zelfstudie",
        buttonName: "Wachtwoord aanvragen",
      },
      {
        heading: "Plus pakket",
        title: "Basic pakket \n+ voorbeeld examenvragen \n+ MC oefenvragen",
        content: "Verkeersreglementering \nVerkeerswetgeving \nAutomechanica",
        footer: "€100/jaar",
        isButton: false,
        buttonLink: " https://buy.stripe.com/8wM9Df1EKcsdf9SbIO",
        buttonName: "Aankopen",
      },
      {
        heading: "Compleet pakket",
        title: "Plus pakket + 2 handboeken",
        content: "Wegverkeerswet \nWegcode",
        footer: "€175,00/jaar",
        isButton: false,
        buttonLink: "https://buy.stripe.com/5kA4iV1EKgIte5OcMQ",
        buttonName: "Aankopen",
      },
    ],
  },
];

const Prijs = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Prijs rijinstructeur</title>
        <meta
          name="description"
          content="Bekijk de prijs voor een opleiding als rijinstructeur. Krijg toegang tot het nodige lesmateriaal, afhankelijk van het gekozen lespakket."
        />
      </Helmet>
      <Container sx={c.wrapperTop}>
        <Typography variant="h2">Prijs rijinstructeur lespakketten</Typography>
      </Container>
      <Box sx={c.wrapperBottom}>
        <Container sx={c.container}>
          <Box sx={c.wrapperList}>
            {dummyData?.map((item) => {
              return (
                <PriceListCard
                  key={item.numberList}
                  numberList={item.numberList}
                  titleList={item.titleList}
                  subTitleList={item.subTitleList}
                  descriptionList={item.descriptionList}
                  ytUrl={item.ytList}
                  data={item.data}
                />
              );
            })}
          </Box>
        </Container>
      </Box>
      <Box sx={c.wrapperWorkshop}>
        <Container sx={c.wrapperContainer}>
          <Box>
            <Typography
              sx={c.wrapperWorkshop__Title}
              variant="h4"
              component={"p"}
            >
              Wilt u de data weten voor de workshops van het belevingstraject
              2025
            </Typography>
          </Box>
          <Box>
            <Button href="/workshops" sx={g.button.secondary}>
              Bekijk programma
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Prijs;
