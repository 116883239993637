import React from "react";
import Footer from "src/components/frontend/Footer";
import Navbar from "src/components/frontend/Navbar";
import Title from "src/components/frontend/Title";
import StaticContent from "src/components/moleculs/StaticContent";

const AlgemeneVoorwaarden = () => {
  return (
    <>
      <Navbar />
      <Title text="Algemene Voorwaarden" />
      <StaticContent>
        <p>
          Termify (“we,” “our,” or “us”) is committed to protecting your
          privacy. This Privacy Policy explains how your personal information is
          collected, used, and disclosed by Termify.
        </p>
        <p>
          This Privacy Policy applies to our website, https://termify.io, and
          its associated subdomains (collectively, our “Service”) alongside our
          application, Termify. By accessing or using our Service, you signify
          that you have read, understood, and agree to our collection, storage,
          use, and disclosure of your personal information as described in this
          Privacy Policy and our Terms of Service.
        </p>

        <h1>Definitions and key terms</h1>
        <p>
          To help explain things as clearly as possible in this Privacy Policy,
          every time any of these terms are referenced, are strictly defined as:
        </p>
        <ul>
          <li>
            Cookie: small amount of data generated by a website and saved by
            your web browser. It is used to identify your browser, provide
            analytics, remember information about you such as your language
            preference or login information.
          </li>
          <li>
            Company: when this policy mentions “Company,” “we,” “us,” or “our,”
            it refers to Termify that is responsible for your information under
            this Privacy Policy.
          </li>
          <li>
            Customer: refers to the company, organization or person that signs
            up to use the Termify Service to manage the relationships with your
            consumers or service users.
          </li>
          <li>
            Device: any internet connected device such as a phone, tablet,
            computer or any other device that can be used to visit Termify and
            use the services.
          </li>
          <li>
            Personal Data: any information that directly, indirectly, or in
            connection with other information — including a personal
            identification number — allows for the identification or
            identifiability of a natural person.
          </li>
          <li>
            Service: refers to the service provided by Termify as described in
            the relative terms (if available) and on this platform.
          </li>
          <li>
            Third-party service: refers to advertisers, contest sponsors,
            promotional and marketing partners, and others who provide our
            content or whose products or services we think may interest you.
          </li>
          <li>
            Website: Termify’s site, which can be accessed via this URL:
            https://termify.io
          </li>
          <li>
            You: a person or entity that is registered with Termify to use the
            Services.
          </li>
        </ul>

        <h1>What Information Do We Collect?</h1>
        <p>
          We collect information from you when you visit our website, register
          on our site, place an order, subscribe to our newsletter, respond to a
          survey or fill out a form.
        </p>
        <ul>
          <li>Phone Numbers</li>
          <li>Email Addresses</li>
          <li>Name / username</li>
          <li>Billing Addresses</li>
        </ul>

        <h1>How Do We Use The Information We Collect?</h1>
        <p>
          Any of the information we collect from you may be used in one of the
          following ways:
        </p>
        <ul>
          <li>
            To personalize your experience (your information helps us to better
            respond to your individual needs)
          </li>
          <li>
            To improve our website (we continually strive to improve our website
            offerings based on the information and feedback we receive from you)
          </li>
          <li>
            To improve customer service (your information helps us to more
            effectively respond to your customer service requests and support
            needs)
          </li>
          <li>To process transactions</li>
          <li>
            To administer a contest, promotion, survey or other site feature
          </li>
          <li>To send periodic emails</li>
        </ul>
        <p></p>

        <h1>How Do We Use Your Email Address?</h1>
        <p>
          By submitting your email address on this website, you agree to receive
          emails from us. You can cancel your participation in any of these
          email lists at any time by clicking on the opt-out link or other
          unsubscribe option that is included in the respective email. We only
          send emails to people who have authorized us to contact them, either
          directly, or through a third party. We do not send unsolicited
          commercial emails, because we hate spam as much as you do. By
          submitting your email address, you also agree to allow us to use your
          email address for customer audience targeting on sites like Facebook,
          where we display custom advertising to specific people who have
          opted-in to receive communications from us. Email addresses submitted
          only through the order processing page will be used for the sole
          purpose of sending you information and updates pertaining to your
          order. If, however, you have provided the same email to us through
          another method, we may use it for any of the purposes stated in this
          Policy. Note: If at any time you would like to unsubscribe from
          receiving future emails, we include detailed unsubscribe instructions
          at the bottom of each email.
        </p>

        <h1>How Long Do We Keep Your Information?</h1>
        <p>
          We keep your information only so long as we need it to provide Termify
          to you and fulfill the purposes described in this policy. This is also
          the case for anyone that we share your information with and who
          carries out services on our behalf. When we no longer need to use your
          information and there is no need for us to keep it to comply with our
          legal or regulatory obligations, we’ll either remove it from our
          systems or depersonalize it so that we can't identify you.
        </p>

        <h1>How Do We Protect Your Information?</h1>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information when you place an order or enter, submit, or
          access your personal information. We offer the use of a secure server.
          All supplied sensitive/credit information is transmitted via Secure
          Socket Layer (SSL) technology and then encrypted into our Payment
          gateway providers database only to be accessible by those authorized
          with special access rights to such systems, and are required to keep
          the information confidential. After a transaction, your private
          information (credit cards, social security numbers, financials, etc.)
          is never kept on file. We cannot, however, ensure or warrant the
          absolute security of any information you transmit to Termify or
          guarantee that your information on the Service may not be accessed,
          disclosed, altered, or destroyed by a breach of any of our physical,
          technical, or managerial safeguards.
        </p>

        <h1>Your Consent</h1>
        <p>
          By using our site, registering an account, or making a purchase, you
          consent to this Privacy Policy.
        </p>

        <h1>Links to Other Websites</h1>
        <p>
          This Privacy Policy applies only to the Services. The Services may
          contain links to other websites not operated or controlled by Termify.
          We are not responsible for the content, accuracy or opinions expressed
          in such websites, and such websites are not investigated, monitored or
          checked for accuracy or completeness by us. Please remember that when
          you use a link to go from the Services to another website, our Privacy
          Policy is no longer in effect. Your browsing and interaction on any
          other website, including those that have a link on our platform, is
          subject to that website’s own rules and policies. Such third parties
          may use their own cookies or other methods to collect information
          about you.
        </p>

        <h1>Cookies</h1>
        <p>
          Termify uses "Cookies" to identify the areas of our website that you
          have visited. A Cookie is a small piece of data stored on your
          computer or mobile device by your web browser. We use Cookies to
          personalize the Content that you see on our website. Most web browsers
          can be set to disable the use of Cookies. However, if you disable
          Cookies, you may not be able to access functionality on our website
          correctly or at all. We never place Personally Identifiable
          Information in Cookies.
        </p>

        <h1>Remarketing Services</h1>
        <p>
          We use remarketing services. What Is Remarketing? In digital
          marketing, remarketing (or retargeting) is the practice of serving ads
          across the internet to people who have already visited your website.
          It allows your company to seem like they're “following” people around
          the internet by serving ads on the websites and platforms they use
          most.
        </p>

        <h1>Payment Details</h1>
        <p>
          In respect to any credit card or other payment processing details you
          have provided us, we commit that this confidential information will be
          stored in the most secure manner possible.
        </p>

        <h1>Kids' Privacy</h1>
        <p>
          We do not address anyone under the age of 13. We do not knowingly
          collect personally identifiable information from anyone under the age
          of 13. If You are a parent or guardian and You are aware that Your
          child has provided Us with Personal Data, please contact Us. If We
          become aware that We have collected Personal Data from anyone under
          the age of 13 without verification of parental consent, We take steps
          to remove that information from Our servers.
        </p>

        <h1>Changes To Our Privacy Policy</h1>
        <p>
          If we decide to change our privacy policy, we will post those changes
          on this page, and/or update the Privacy Policy modification date
          below.
        </p>

        <h1>Third-Party Services</h1>
        <p>
          We may display, include or make available third-party content
          (including data, information, applications and other products
          services) or provide links to third-party websites or services
          ("Third- Party Services").
        </p>
        <p>
          You acknowledge and agree that Termify shall not be responsible for
          any Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency, quality
          or any other aspect thereof. Termify does not assume and shall not
          have any liability or responsibility to you or any other person or
          entity for any Third-Party Services.
        </p>
        <p>
          Third-Party Services and links thereto are provided solely as a
          convenience to you and you access and use them entirely at your own
          risk and subject to such third parties' terms and conditions.
        </p>

        <h1>Information about General Data Protection Regulation (GDPR)</h1>
        <p>
          We may be collecting and using information from you if you are from
          the European Economic Area (EEA), and in this section of our Privacy
          Policy we are going to explain exactly how and why is this data
          collected, and how we maintain this data under protection from being
          replicated or used in the wrong way.
        </p>

        <h1>What is GDPR?</h1>
        <p>
          GDPR is an EU-wide privacy and data protection law that regulates how
          EU residents' data is protected by companies and enhances the control
          the EU residents have, over their personal data.
        </p>
        <p>
          The GDPR is relevant to any globally operating company and not just
          the EU-based businesses and EU residents. Our customers’ data is
          important irrespective of where they are located, which is why we have
          implemented GDPR controls as our baseline standard for all our
          operations worldwide.
        </p>

        <h1>What is personal data?</h1>
        <p>
          Any data that relates to an identifiable or identified individual.
          GDPR covers a broad spectrum of information that could be used on its
          own, or in combination with other pieces of information, to identify a
          person. Personal data extends beyond a person’s name or email address.
          Some examples include financial information, political opinions,
          genetic data, biometric data, IP addresses, physical address, sexual
          orientation, and ethnicity.
        </p>
        <p>The Data Protection Principles include requirements such as:</p>

        <ul>
          <li>
            Personal data collected must be processed in a fair, legal, and
            transparent way and should only be used in a way that a person would
            reasonably expect.
          </li>
          <li>
            Personal data should only be collected to fulfil a specific purpose
            and it should only be used for that purpose. Organizations must
            specify why they need the personal data when they collect it.
          </li>
          <li>
            Personal data should be held no longer than necessary to fulfil its
            purpose.
          </li>
          <li>
            People covered by the GDPR have the right to access their own
            personal data. They can also request a copy of their data, and that
            their data be updated, deleted, restricted, or moved to another
            organization.
          </li>
        </ul>

        <h1>California Residents</h1>
        <p>
          The California Consumer Privacy Act (CCPA) requires us to disclose
          categories of Personal Information we collect and how we use it, the
          categories of sources from whom we collect Personal Information, and
          the third parties with whom we share it, which we have explained
          above.
        </p>
        <p>
          We are also required to communicate information about rights
          California residents have under California law. You may exercise the
          following rights:
        </p>
        <ul>
          <li>
            Right to Know and Access. You may submit a verifiable request for
            information regarding the: (1) categories of Personal Information we
            collect, use, or share; (2) purposes for which categories of
            Personal Information are collected or used by us; (3) categories of
            sources from which we collect Personal Information; and (4) specific
            pieces of Personal Information we have collected about you.
          </li>
          <li>
            Right to Equal Service. We will not discriminate against you if you
            exercise your privacy rights.
          </li>
          <li>
            Right to Delete. You may submit a verifiable request to close your
            account and we will delete Personal Information about you that we
            have collected.
          </li>
          <li>
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </li>
        </ul>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        <p>We do not sell the Personal Information of our users.</p>
        <p>For more information about these rights, please contact us.</p>

        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h1>Contact Us</h1>
        <p>Don't hesitate to contact us if you have any questions.</p>
        <ul>
          <li>
            Via Email:{" "}
            <a
              href="/cdn-cgi/l/email-protection"
              a-cfemail="2d5e585d5d425f596d59485f40444b54034442"
            >
              [email&#160;protected]
            </a>
          </li>
          <li>Via this Link: https://termify.io/contact</li>
        </ul>
      </StaticContent>
      <Footer />
    </>
  );
};

export default AlgemeneVoorwaarden;
