import { AsSxObject } from "src/utils/AsSxObject";

const LoadingStyle = AsSxObject({
  wrapper: {
    position: "fixed",
    inset: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255,255,255,.5)",
  },
});

export default LoadingStyle;
