import g from "src/utils/MuiStyle/Global";
const { AsSxObject } = require("src/utils/AsSxObject");

const WrapperContentStyle = AsSxObject({
  Wrapper: {
    "@supports not (aspect-ratio: 1)": {
      paddingTop: "56.25%",
      position: "relative",
    },
    marginBlock: "24px",
    aspectRatio: "16/9",
    iframe: {
      "@supports not (aspect-ratio: 1)": {
        position: "absolute",
        inset: 0,
      },
      borderRadius: "10px",
    },
  },
});

export default WrapperContentStyle;
