import Sidebar from "src/components/moleculs/Sidebar";
import CourseComponent from "src/components/backend/Course/Course";
import { useState, useEffect } from "react";
import SwalCatch from "src/utils/helpers/SwalCatch";
import { slideSidebar } from "src/services/slides";
import axios from "axios";
import Loading from "src/components/atoms/Loading";
import { useMemo } from "react";
import useRole from "src/hooks/useRole";

export default function Course() {
  const [render, rerender] = useState(false);
  const [data, setData] = useState([]);
  const [hierarkiData, setHierarkiData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    axios
      .get("/api/courses-sidebar")
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          const errors = res.data?.errors;
          SwalCatch(errors, true);
        }
        const newData = res.data;
        setData(newData);
        rerender(true);
      })
      .catch((err) => {
        const errors = err?.response?.data?.errors;
        SwalCatch(errors, true);
      });

    return () => {
      isMounted = false;
      rerender(false);
    };
  }, []);

  const role = useRole();

  useMemo(() => {
    const hierarkiData_ = slideSidebar(data);

    if (role === 3) {
      setHierarkiData(hierarkiData_);
    } else {
      setHierarkiData(
        hierarkiData_.filter((item) => item.slug !== "methodiek")
      );
    }
  }, [data]);

  return !render ? (
    <Loading />
  ) : (
    <>
      <Sidebar isSm data={hierarkiData} uri="course" />
      <CourseComponent firstData={hierarkiData[0] ?? []} />
    </>
  );
}
