import Sidebar from "src/components/moleculs/Sidebar";
import AccountComponent from "src/components/backend/Account";
import Footer from "src/components/backend/Dashboard/Footer/Footer";

export default function Account() {
  return (
    <>
      <Sidebar />
      <AccountComponent />
    </>
  );
}
