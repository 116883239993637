const data = [
  {
    title:
      "Verkeersbord C3 + onderbord ‘uitgezonderd plaatselijk verkeer’. Wie mag niet op zo’n openbare weg:",
    content:
      '<img class="alignnone size-medium wp-image-51" src="https://admin.platform-rijschoollesgever.be/wp-content/uploads/2022/09/Picture1-251x300.png" alt="" width="251" height="300" />',
    questions: {
      type: "multiple_choice",
      question:
        "Verkeersbord C3 + onderbord ‘uitgezonderd plaatselijk verkeer’. Wie mag niet op zo’n openbare weg:",
      answer: [
        {
          awnser: "fietsers",
          correct: false,
        },
        {
          awnser: "voertuig van bewoner",
          correct: false,
        },
        {
          awnser: "voertuigen voor levering",
          correct: false,
        },
        {
          awnser: "bromfiets klasse A",
          correct: true,
        },
        {
          awnser: "fieters",
          correct: false,
        },
        {
          awnser: "Feedback: art. 2.47 en art. 68.3 ",
          correct: false,
        },
      ],
    },
  },
  {
    title: "Een aanhangwagen is",
    content: "",
    questions: {
      type: "multiple_choice",
      question: "Een aanhangwagen is ",
      answer: [
        {
          awnser: "elk voertuig dat door een ander wordt voortbewogen",
          correct: false,
        },
        {
          awnser:
            "elke groep voertuigen die aan elkander gekoppeld zijn met het doel door een en dezelfde kracht te worden voortbewogen",
          correct: false,
        },
        {
          awnser:
            "elk voertuig dat bestemd is om door aan ander te worden voortbewogen",
          correct: true,
        },
        {
          awnser:
            "een voertuig dat achter een ander voertuig wordt aangekoppeld",
          correct: false,
        },
        {
          awnser: "elk goed of materiaal dat door een voertuig wordt vervoerd",
          correct: false,
        },
        {
          awnser: "Feedback : art. 2.24",
          correct: false,
        },
      ],
    },
  },
  {
    title: "De rijbaan is:",
    content: "",
    questions: {
      type: "multiple_choice",
      question: "De rijbaan is: ",
      answer: [
        {
          awnser:
            "het deel van de openbare weg dat is ingericht voor het voertuigenverkeer in het algemeen",
          correct: true,
        },
        {
          awnser:
            "het deel van de openbare weg dat is ingericht voor het autoverkeer in het algemeen",
          correct: false,
        },
        {
          awnser: "de ganse openbare weg ",
          correct: false,
        },
        {
          awnser:
            "het deel van de openbare weg dat is ingericht voor het verkeer in het algemeen",
          correct: false,
        },
        {
          awnser:
            "elk deel van de openbare weg die in haar langsrichting verdeeld is door één of meer witte doorlopende of onderbroken strepen",
          correct: false,
        },
        {
          awnser: "Feedback : art. 2.1",
          correct: false,
        },
      ],
    },
  },
  {
    title: "Een speelstraat is:",
    content: "",
    questions: {
      type: "multiple_choice",
      question: "Een speelstraat is: ",
      answer: [
        {
          awnser: "een straat waar alleen spelende kinderen mogen komen",
          correct: false,
        },
        {
          awnser:
            "een openbare weg waar tijdelijk en tijdens bepaalde uren aan de toegang een hekken geplaatst wordt met het verkeersbord C3 en een onderbord “speelstraat” ",
          correct: true,
        },
        {
          awnser: "een straat in een woonerf waar spelen is toegelaten",
          correct: false,
        },
        {
          awnser:
            "een straat waar tijdelijk en tijdens bepaalde uren speeltuigen worden geplaatst",
          correct: false,
        },
        {
          awnser:
            "is een openbare weg voorbehouden voor het spelen, in hoofdzaak door kinderen",
          correct: false,
        },
        {
          awnser: "Ini BukanFeedback : art. 2.36",
          correct: false,
        },
      ],
    },
  },
];

const restructureQuestion = (item) => {
  let result = {};

  let newAnswer = [];
  let msg = "";
  item.questions.answer.forEach((ans, j) => {
    if (j === item.questions.answer.length - 1) {
      if (ans.awnser.slice(0, 8) !== "Feedback") {
        newAnswer[j] = ans;
      } else {
        msg = ans.awnser;
      }
    } else {
      newAnswer[j] = ans;
    }
  });

  result = {
    ...item,
    questions: {
      ...item.questions,
      answer: newAnswer,
      messageIsTrue: msg !== "" ? msg : "Jij hebt het!",
    },
  };
  return result;
};

export default restructureQuestion;
