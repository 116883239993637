import Footer from "src/components/frontend/Footer";
import Navbar from "src/components/frontend/Navbar";
import TabsSection from "src/components/frontend/TabsSection";
import Title from "src/components/frontend/Title";

const Home = () => {
  return (
    <>
      <Navbar />
      <Title text="Rijinstructeur opleiding" />
      <TabsSection />
      <Footer />
    </>
  );
};

export default Home;
