const { AsSxObject } = require("src/utils/AsSxObject");

const TitleStyle = AsSxObject({
  style: {
    color: (t) => t.palette.primary.main,
    textAlign: "center",
    marginTop: "55px",
    marginBottom: "85px",
    fontFamily: '"metropolis-bold"',
    "@media(max-width: 600px)": {
      marginBottom: "55px",
    },
  },
});

export default TitleStyle;
