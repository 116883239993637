import { Box } from "@mui/material";
import c from "./YtEmbedStyle";
import React from "react";

/**
 * @typedef {object} IYtEmbed
 * @property {string} url
 * @property {string} [title]
 *
 * @param {IYtEmbed} param0
 * @returns
 */
const YtEmbed = ({ url, title = "YouTube video player" }) => {
  const code = new URL(url).search.split("&")[0].replace("?v=", "");
  return (
    <Box sx={c.Wrapper}>
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${code}?controls=0`}
        title={title}
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </Box>
  );
};

export default YtEmbed;
