import { createSlice } from "@reduxjs/toolkit";

/**
 * @type {UserAccount}
 */
const initialState = {
  userToken: null,
  refreshToken: null,
  expires: null,
};

export const userAccountSlice = createSlice({
  name: "userAccount",
  initialState,
  reducers: {
    /**
     * @type {UtilReducer<UserAccount, UserAccount['userToken']>}
     */
    setToken: (state, action) => ({
      ...state,
      userToken: action.payload,
    }),

    /**
     * @type {UtilReducer<UserAccount, UserAccount>}
     */
    setMulti: (state, action) => ({
      ...state,
      ...action.payload,
      userToken: action.payload.userToken,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setMulti } = userAccountSlice.actions;

export default userAccountSlice.reducer;
