const { AsSxObject } = require("src/utils/AsSxObject");

const ModellesStyle = AsSxObject({
  wrapper: {
    marginTop: "40px",
  },
  desc: {
    marginBlock: "48px",
    maxWidth: "648px",
    "@media(max-width: 1020px)": {
      marginBlock: "30px",
    },
  },
  wrapperListParent: {
    marginTop: "60px",
    display: "flex",
    flexDirection: "column",
    gap: "45px",
  },
  wrapperListGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  wrapperList: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  downloadItem: {
    "@media(max-width: 600px)": {
      flexWrap: "wrap",
      ".MuiTypography-body1": {
        flex: "0 0 100%",
      },
    },
  },
});

export default ModellesStyle;
