import { Box, Button, Container, Typography } from "@mui/material";
import c from "./BlogSingleStyle";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml/SetInnerHtml";
import Card from "../Card/";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const BlogSingle = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get(`api/blogs/${slug}`).then((res) => {
      setData(res.data);
    });
  }, [slug]);

  return (
    <>
      <Container sx={c.topContent}>
        <Typography variant="h2">{data?.data.title}</Typography>
        <Typography variant="body1">
          Gepubliceerd {data?.data.published}
        </Typography>
      </Container>
      <Box
        sx={c.imageBanner}
        component={"img"}
        src={data?.data.image ?? "https://dummyimage.com/1000x400/000/fff.jpg"}
        height={486}
        width={2000}
      />
      <Box id="Blog-Content" component={"section"}>
        <Container>
          <SetInnerHtml className="BlogContent">
            {data?.data.content}
          </SetInnerHtml>
          <Box sx={c.buttonWrapper}>
            <Link to={`/#contact`}>
              <Button variant="contained">Contact ons</Button>
            </Link>
          </Box>
        </Container>
      </Box>
      <Box id="Blog-Related" component={"section"} sx={c.relatearticle}>
        <Container>
          <Typography variant="h3">Gerelateerde artikelen</Typography>
          <Box sx={c.relatearticle__wrapper}>
            {data?.related_blog.map((item) => {
              return (
                <Box key={item.id} sx={c.relatearticle__item}>
                  <Card
                    title={item.title}
                    slug={item.slug}
                    published={item.published}
                    category={item.category}
                    desc={item.sort_desc}
                    image={item.image}
                  />
                </Box>
              );
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BlogSingle;
