import { AsSxObject } from "src/utils/AsSxObject";

const Styles = AsSxObject({
  topContent: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginBottom: "40px",
  },
  imageBanner: {
    objectFit: "cover",
    marginBottom: "40px",
    "@media(max-width: 600px)": {
      height: "200px",
    },
  },
  buttonWrapper: {
    textAlign: "center",
    display: "block",
    marginTop: "40px",
  },
  relatearticle: {
    marginBlock: "100px",
  },
  relatearticle__wrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "130px",
    marginTop: "40px",
  },
  relatearticle__item: {
    flex: "0 0 33.3333%",
    padding: "16px",
    "@media(max-width: 1024px)": {
      flex: "0 0 50%",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
});

export default Styles;
