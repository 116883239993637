import { matchRoutes, Navigate, useLocation } from "react-router-dom";
import useRole from "src/hooks/useRole";
import useStore from "src/hooks/useStore";
import authRoutes from "./authRoutes";
import userRoutes from "./userRoutes";

export default function AppRouter() {
  const location = useLocation();

  // const account = useSelector((state) => state.userAccount);
  const store = useStore();
  const dataUser = store.profileAccount.data;
  // @ts-ignore
  const userRole = useRole();
  // console.log(store.userAccount);
  const isLogin = store.userAccount.userToken;
  const isAuthRoutes = matchRoutes(authRoutes, location);
  const isUserRoutes = matchRoutes(userRoutes(userRole), location);

  if (isUserRoutes && !isLogin) return <Navigate to="/" />;

  if (isAuthRoutes && isLogin) return <Navigate to="/dashboard" />;

  return null;
}
