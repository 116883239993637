const { AsSxObject } = require("src/utils/AsSxObject");

const SlidesStyle = AsSxObject({
  modal: {
    backgroundColor: "rgba(0,0,0, 0.4)",
    overflow: "auto",
  },
  modalWrapper: {
    padding: "20px",
    maxWidth: "1300px",
    marginInline: "auto",
    "&:focus-visible": {
      outline: "none",
    },
  },
  footerSlide: {
    display: "flex",
    justifyContent: "space-between",
  },
  footerSlidePlusMinus: {
    display: "flex",
    gap: "16px",
  },
  pembatasAntaraQueastionDan_________________________________________________________________________________:
    {},
  questionWrapper: {
    maxWidth: "600px",
    marginInline: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  questionImage: {
    width: "100%",
    objectFit: "contain",
    height: "250px",
    marginBottom: "16px",
    "@media(min-width: 1025px)": {
      height: "360px",
    },
  },
  questionLabelTrue: {
    background: "aqua",
  },
  questionLabelFalse: {
    background: "red",
  },
  footerBack: {
    display: "flex",
    justifyContent: "flex-start",
    position: "absolute",
    bottom: "0",
    left: "0",
  },
  circleIconTrue: {
    fontFamily: "Metropolis-Regular",
    padding: "24px",
    width: "10px",
    height: "10px",
    minWidth: "0",
    backgroundColor: "#2946D6",
    color: "#E4E6F5",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#2946D6",
      color: "#E4E6F5",
    },
  },
  pembatasAntaraSlidePopupDan_________________________________________________________________________________:
    {},
  slidePopupImage: {
    width: "100%",
    objectFit: "contain",
    marginBottom: "16px",
  },
});

export default SlidesStyle;
