import { Button } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <div id="not-found" className="not-found">
      <div className="fof">
        <h1>Error 404</h1>
        <Button variant="contained" href="/dashboard">
          Terug Naar Dashboard
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
