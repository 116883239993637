import React from "react";
import AuthFooter from "src/components/auth/AuthFooter";
import ResetPassword from "src/components/auth/ResetPassword";

const ReqResetPassword = () => {
  return (
    <>
      <ResetPassword />
      <AuthFooter />
    </>
  );
};

export default ReqResetPassword;
