import React from "react";
import { Box } from "@mui/system";
import c from "./SocialMediaStyle";
import { Facebook, Instagram, Linkedin, Youtube } from "../icons/Svg";
import { Link } from "react-router-dom";

/**
 *
 * @typedef {object} SocialMediaProps
 * @property {object} [sxs]
 * @property {object} [isNone]
 *
 * @param {SocialMediaProps} param1
 */

const SocialMedia = ({ sxs, isNone }) => {
  return (
    <Box sx={{ ...c.wrapper, ...sxs, ...(isNone ? c.isNone : {}) }}>
      <a
        href="https://www.facebook.com/PlatformRijschoollesgever"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Facebook sxs={{ ...c.icon, ...c.iconWhite, ...sxs?.icon }} />
      </a>
      <a
        href="https://www.linkedin.com/company/93835202"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Linkedin sxs={{ ...c.icon, ...c.iconWhite, ...sxs?.icon }} />
      </a>
      <a
        href="https://www.youtube.com/@rij-huis"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Youtube sxs={{ ...c.icon, ...c.iconWhite, ...sxs?.icon }} />
      </a>
      <a
        href="https://www.instagram.com/platformrijschoollesgever"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Instagram sxs={{ ...c.icon, ...c.iconWhite, ...sxs?.icon }} />
      </a>
    </Box>
  );
};

export default SocialMedia;
