import { useDispatch } from "react-redux";
import { logout } from "src/hooks/useManageApi";
import { setToken } from "src/store/userAccount";
import Swal from "sweetalert2";
/**
 *
 * @param {string} msg
 *
 *
 */
const SwalSuccess = (msg) => {
  Swal.fire({
    title: "Success!",
    text: msg,
    icon: "success",
  });
};

export default SwalSuccess;
