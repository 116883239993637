import ModellesOndersteuning from "src/pages/admin/ModellesOndersteuning";
import Account from "src/pages/admin/Account";
import Course from "src/pages/admin/Course/Course";
import Document from "src/pages/admin/Document";
import Faq from "src/pages/admin/Faq/Faq";
import LessonPackages from "src/pages/admin/LessonPackages";
import Slide from "src/pages/admin/Slide";
import Dashboard from "../pages/admin/Dashboard";
import FaqChild from "src/pages/admin/Faq/FaqChild";

const userRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
    exact: true,
  },
  {
    path: "/account",
    element: <Account />,
  },
  {
    path: "/lesson-packages",
    element: <LessonPackages />,
  },
  {
    path: "/course",
    element: <Course />,
  },
  {
    path: "/course/:id",
    element: <Course />,
  },
  {
    path: "/modelles-ondersteuning",
    element: <ModellesOndersteuning />,
  },
  {
    path: "/slide",
    element: <Slide />,
  },
  {
    path: "/slide/:id",
    element: <Slide />,
  },
  {
    path: "/document",
    element: <Document />,
  },
  {
    path: "/document/:id",
    element: <Document />,
  },
  {
    path: "/oefenvragen",
    element: <Faq />,
  },
  // {
  //   path: "/oefenvragen/:id",
  //   element: <Faq />,
  // },
  {
    path: "/oefenvragen/",
    element: <FaqChild />,
  },
  {
    path: "/oefenvragen/:id",
    element: <FaqChild />,
  },
];

const userRoutesFn = (role = 3) => {
  const getRouteItem = (menus, item) => {
    let newMenu = [];
    menus.forEach((menu) => {
      if (item.indexOf(menu.path) !== -1) {
        newMenu.push(menu);
      }
    });

    return newMenu;
  };

  const routeZero = getRouteItem(userRoutes, [
    "/dashboard",
    "/course",
    "/course/:id",
    "/account",
    "/lesson-packages",
  ]);
  const routeOne = getRouteItem(userRoutes, [
    "/dashboard",
    "/course",
    "/course/:id",
    "/account",
    "/lesson-packages",
    "/oefenvragen",
    "/oefenvragen/:id",
  ]);
  const routeTwo = getRouteItem(userRoutes, [
    "/dashboard",
    "/course",
    "/account",
    "/lesson-packages",
    "/oefenvragen",
    "/oefenvragen/:id",
    "/document",
    "/document/:id",
    "/modelles-ondersteuning",
  ]);

  const listRouteObj = {
    0: routeZero,
    1: routeOne,
    2: routeTwo,
    3: userRoutes,
  };

  const renderRoute = listRouteObj[role];
  return renderRoute;
};

export default userRoutesFn;
