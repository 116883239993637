import { Typography } from "@mui/material";
import React from "react";
import c from "./TitleStyle";

/**
 *
 * @typedef {object} TitleComponent
 * @property {string} text
 *
 * @param {TitleComponent} param1
 */

const Title = ({ text }) => {
  return (
    <Typography sx={c.style} variant="h1" component="h1">
      {text}
    </Typography>
  );
};

export default Title;
