import { combineReducers } from "redux";
import profileAccount from "./profileAccount";
import questionOpen from "./questionOpen";
import userAccount from "./userAccount";

export default combineReducers({
  userAccount: userAccount,
  profileAccount: profileAccount,
  questionOpen: questionOpen,
});
