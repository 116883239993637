import g from "src/utils/MuiStyle/Global";
const { AsSxObject } = require("src/utils/AsSxObject");

const ListPaketStyle = AsSxObject({
  plWrapper: {
    display: "flex",
    "@media(max-width: 1025px)": {
      flexWrap: "wrap",
      rowGap: "20px",
    },
    "@media(max-width: 600px)": {
      rowGap: "16px",
    },
  },
  plSubWrapper: {
    display: "flex",
    columnGap: "16px",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 20px",
    "@media(max-width: 1025px)": {
      justifyContent: "center",
    },
  },
  plSub1: {
    flex: "0 0 29%",
    "@media(max-width: 1025px)": {
      flex: "0 0 50%",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  plSub2: {
    flex: "0 0 23%",
    padding: 0,
    "@media(max-width: 1025px)": {
      flex: "0 0 50%",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  plSub3: {
    flex: "0 0 20%",
    "@media(max-width: 1025px)": {
      flex: "0 0 33.333%",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  plSub4: {
    flex: "0 0 13%",
    "@media(max-width: 1025px)": {
      flex: "0 0 33.333%",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  plSub5: {
    flex: "0 0 15%",
    "@media(max-width: 1025px)": {
      flex: "0 0 33.333%",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  plDivider: {
    marginRight: "30px",
    "@media(max-width: 600px)": {
      display: "none",
    },
  },
  plTitle: {
    fontFamily: "Metropolis-Bold",
    fontSize: "18px",
  },
  plDesc: {
    fontSize: "18px",
    whiteSpace: "pre",
    "@media(max-width: 1366px)": {
      fontSize: "16px",
    },
  },
  plPrice: {
    fontFamily: "Metropolis-Bold",
    fontSize: "18px",
    color: g.color.primary,
  },
  plLogo: {
    width: "50px",
    objectFit: "contain",
  },
});

export default ListPaketStyle;
