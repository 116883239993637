// @ts-nocheck
import { Helmet } from "react-helmet-async";
import AuthFooter from "src/components/auth/AuthFooter";
import LoginComponent from "src/components/auth/Login/";

export default function Logina() {
  return (
    <>
      <Helmet>
        <title>Aanmelden</title>
        <meta
          name="description"
          content="Meld je aan op Platform rijschoollesgever en krijg toegang tot al het studiemateriaal om te slagen als rijschoolinstructeur."
        />
      </Helmet>
      <LoginComponent />
      <AuthFooter />
    </>
  );
}
