import { Box, Button } from "@mui/material";
import { useRef } from "react";
import c from "./NavbarStyles";
import hamburger from "src/components/atoms/icons/hamburger.svg";
import close from "src/components/atoms/icons/crosx.svg";
import { Link } from "react-router-dom";
import ScrollToTop from "src/components/atoms/ScrollToTop";
import useMailto from "src/hooks/useMailto";

const Navbar = () => {
  const refMenu = useRef(null);

  const toggleMenu = () => {
    refMenu.current.classList.toggle("open");
  };

  const handleClickEmail = (e) => {
    e.preventDefault();

    useMailto("info@platform-rijschoollesgever.be", "", "");
  };

  return (
    <>
      <ScrollToTop />
      <Box sx={c.topHeader}>
        <Box sx={c.topHeaderContainer}>
          <Box sx={c.topHeaderCol}>
            <a href={"tel: +32472809544"}>+32472809544</a>
            <a
              href={"mailto: info@platform-rijschoollesgever.be"}
              onClick={handleClickEmail}
            >
              info@platform-rijschoollesgever.be
            </a>
          </Box>
          <Box sx={c.topHeaderCol}>
            {/* @ts-ignore */}
            <Link to={"/vacatures"}>Vacatures</Link>
            <Link to={"/blogs"}>Blog</Link>
          </Box>
        </Box>
      </Box>
      <Box sx={c.wrapper} className="Navbar">
        <Link to={"/"}>
          <Box
            component="img"
            src="/images/logo.svg"
            alt="Logo"
            sx={c.logoImg}
          ></Box>
        </Link>
        <Box
          sx={c.hamburgerMobile}
          onClick={toggleMenu}
          component="img"
          src={hamburger}
        />
        <Box sx={c.cta} ref={refMenu}>
          <Box
            sx={c.closeMobile}
            onClick={toggleMenu}
            component="img"
            src={close}
          />
          <Link to="/login">
            <Button sx={c.button} variant="text">
              Aanmelden
            </Button>
          </Link>
          <Link to="/register">
            <Button sx={c.buttonContained} variant="contained">
              Registreren
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;
