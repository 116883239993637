import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useForm from "src/hooks/useForm";
import { setData } from "src/store/profileAccount";
import { setToken } from "src/store/userAccount";
import SwalCatch from "src/utils/helpers/SwalCatch";
import c from "./LoginFormStyle";

const LoginForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { payload, fnOnChange, valid, fnOnInvalid, message, fnIsValid } =
    useForm({
      email: "",
      password: "",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post("/api/login", payload)
      .then((res) => {
        const userToken = res.data.access_token;
        dispatch(setToken(userToken));

        axios
          .get("/api/user", {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((res) => {
            const data = res.data.date;
            dispatch(setData(data));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        const errors = [{ message: err?.response?.data?.message }];
        console.log("ik komh ir !!");
        SwalCatch(errors);
      });
  };

  return (
    <Box
      sx={c.forms}
      component={"form"}
      onSubmit={handleSubmit}
      ref={formRef}
      noValidate
    >
      <Link to="/">
        <Box
          sx={c.formLogo}
          component="img"
          alt="Logo"
          src="/images/logo.svg"
        ></Box>
      </Link>

      <TextField
        sx={c.field}
        id="email"
        label="E-mail"
        variant="outlined"
        name="email"
        onChange={fnOnChange("email")}
        onInvalid={fnOnInvalid("email", "Het e-mail moet ingevuld zijn!")}
        required
        value={payload.email}
        // @ts-ignore
        error={valid.email}
        helperText={message.email}
      />
      <TextField
        sx={c.field}
        id="password"
        label="Wachtwoord"
        variant="outlined"
        name="password"
        type="password"
        onChange={fnOnChange("password")}
        onInvalid={fnOnInvalid(
          "password",
          " Het wachtwoord moet ingevuld zijn!"
        )}
        required
        value={payload.password}
        // @ts-ignore
        error={valid.password}
        helperText={message.password}
      />

      <Typography sx={c.caption}>
        <Link to="/reset-password" className="link">
          Wachtwoord vergeten?
        </Link>
      </Typography>

      <Button sx={c.button} variant="contained" type="submit">
        Aanmelden
      </Button>

      <Typography sx={c.caption}>
        Nog niet geregistreerd?{" "}
        <Link to="/register" className="link">
          Registreer je hier
        </Link>
      </Typography>
    </Box>
  );
};

export default LoginForm;
