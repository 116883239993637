import { Box, Card, Divider, Typography } from "@mui/material";
import c from "./ListPaketStyle";

/**
 * @typedef {object} ListPaket
 *
 * @property {string} title1
 * @property {string} title2
 * @property {string} desc1
 * @property {string} desc2
 * @property {string} price
 *
 * @param {ListPaket} param1
 *
 */

const ListPaket = ({ title1, title2, desc1, desc2, price }) => {
  return (
    <Card variant="elevation" className="el-AccListPakket">
      <Box sx={c.plWrapper}>
        <Box sx={{ ...c.plSubWrapper, ...c.plSub1 }}>
          <Box
            sx={c.plLogo}
            component="img"
            src="/images/logo-sm.svg"
            alt="Image Account"
          />
          <Typography variant="body1" sx={c.plTitle}>
            {title1}
          </Typography>
        </Box>
        <Box sx={{ ...c.plSubWrapper, ...c.plSub2 }}>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={c.plDivider}
          />
          <Typography variant="body1" sx={c.plTitle}>
            {title2}
          </Typography>
        </Box>
        <Box
          sx={{ ...c.plSubWrapper, ...c.plSub3 }}
          className="el-AccListPakketDesc"
        >
          <Typography variant="body1" sx={c.plDesc}>
            {desc1}
          </Typography>
        </Box>
        <Box
          sx={{ ...c.plSubWrapper, ...c.plSub4 }}
          className="el-AccListPakketDesc"
        >
          <Typography variant="body1" sx={c.plDesc}>
            {desc2}
          </Typography>
        </Box>
        <Box sx={{ ...c.plSubWrapper, ...c.plSub5 }}>
          <Typography variant="body1" sx={c.plPrice}>
            {price}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default ListPaket;
