import Sidebar from "src/components/moleculs/Sidebar";
import ModellesComp from "src/components/backend/ModellesOndersteuning";

export default function ModellesOndersteuning() {
  return (
    <>
      <Sidebar />
      <ModellesComp />
    </>
  );
}
