import { AsSxObject } from "src/utils/AsSxObject";

const NavbarStyles = AsSxObject({
  topHeader: {
    background: (t) => t.palette.primary.light,
    position: "sticky",
    top: "0",
    zIndex: "999",
  },
  topHeaderContainer: {
    width: "100%",
    padding: "6px 42px",
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width: 600px)": {
      flexDirection: "column",
      padding: "6px 16px",
      gap: "8px",
      alignItems: "center",
    },
  },
  topHeaderCol: {
    display: "flex",
    alignItems: "center",
    gap: "32px",
    "@media(max-width: 600px)": {
      gap: "16px",
    },
    a: {
      color: (t) => t.palette.primary.dark,
      fontFamily: "Metropolis-Medium",
      fontSize: "14px",
      "@media(max-width: 600px)": {
        fontSize: "12px",
        fontFamily: "Metropolis-Medium",
      },
    },
  },
  wrapper: {
    width: "100%",
    padding: "24px 42px",
    display: "flex",
    position: "sticky",
    top: "40px",
    zIndex: "999",
    background: "white",
    justifyContent: "space-between",
    "@media(max-width: 600px)": {
      padding: "20px 20px",
      top: "75px",
    },
  },
  cta: {
    display: "flex",
    gap: "22px",
    alignItems: "center",
    "@media(max-width: 600px)": {
      background: "rgba(255, 255, 255, 0.4)",
      backdropFilter: "blur(6.5px)",
      inset: 0,
      position: "fixed",
      zIndex: 99,
      flexDirection: "column",
      padding: "20px",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      display: "flex",
      transform: "translateX(100%)",
      transition: "transform 0.3s ease-in-out",
      "@-moz-document url-prefix()": {
        backgroundColor: "rgba(255, 255, 255, 1)",
      },
    },
    "&.open": {
      dislay: "flex",
      transform: "translateX(0)",
      transition: "transform 0.3s ease-in-out",
    },
  },
  hamburgerMobile: {
    display: "none",
    "@media(max-width: 600px)": {
      display: "block",
      width: "30px",
      height: "30px",
      objectFit: "contain",
      marginLeft: "auto",
    },
  },
  closeMobile: {
    position: "absolute",
    top: "20px",
    display: "none",
    "@media(max-width: 600px)": {
      display: "block",
      width: "24px",
      height: "24px",
      objectFit: "contain",
    },
    right: "20px",
  },
  button: {
    fontFamily: "Metropolis-Bold",
    "&:hover": {
      boxShadow: "none",
    },
  },
  buttonContained: {
    fontFamily: "Metropolis-Bold",
  },
  logoImg: {
    maxWidth: "228px",
    width: "100%",
    height: "50px",
    objectFit: "contain",
    "@media(max-width: 600px)": {
      maxWidth: "150px",
      height: "30px",
    },
  },
});

export default NavbarStyles;
