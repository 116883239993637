import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Home
 * @property {object} [sxs]
 *
 * @param {Home} param1
 */

export default function IcSalaryLabel({ sxs }) {
  return (
    <SvgIcon sx={sxs}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#2946D6" />
        <path
          d="M22.6133 19.1667C22.3933 20.9401 20.94 22.3934 19.1667 22.6134C18.0933 22.7467 17.0933 22.4534 16.3133 21.8801C15.8667 21.5534 15.9733 20.8601 16.5067 20.7001C18.5133 20.0934 20.0933 18.5067 20.7067 16.5001C20.8667 15.9734 21.56 15.8667 21.8867 16.3067C22.4533 17.0934 22.7467 18.0934 22.6133 19.1667Z"
          fill="#F4F6FF"
        />
        <path
          d="M14.6599 9.33325C11.7199 9.33325 9.33325 11.7199 9.33325 14.6599C9.33325 17.5999 11.7199 19.9866 14.6599 19.9866C17.5999 19.9866 19.9866 17.5999 19.9866 14.6599C19.9799 11.7199 17.5999 9.33325 14.6599 9.33325ZM14.0333 13.9133L15.6399 14.4733C16.2199 14.6799 16.4999 15.0866 16.4999 15.7133C16.4999 16.4333 15.9266 17.0266 15.2266 17.0266H15.1666V17.0599C15.1666 17.3333 14.9399 17.5599 14.6666 17.5599C14.3933 17.5599 14.1666 17.3333 14.1666 17.0599V17.0199C13.4266 16.9866 12.8333 16.3666 12.8333 15.5933C12.8333 15.3199 13.0599 15.0933 13.3333 15.0933C13.6066 15.0933 13.8333 15.3199 13.8333 15.5933C13.8333 15.8333 14.0066 16.0266 14.2199 16.0266H15.2199C15.3733 16.0266 15.4933 15.8866 15.4933 15.7133C15.4933 15.4799 15.4533 15.4666 15.2999 15.4133L13.6933 14.8533C13.1199 14.6533 12.8333 14.2466 12.8333 13.6133C12.8333 12.8933 13.4066 12.2999 14.1066 12.2999H14.1666V12.2733C14.1666 11.9999 14.3933 11.7733 14.6666 11.7733C14.9399 11.7733 15.1666 11.9999 15.1666 12.2733V12.3133C15.9066 12.3466 16.4999 12.9666 16.4999 13.7399C16.4999 14.0133 16.2733 14.2399 15.9999 14.2399C15.7266 14.2399 15.4999 14.0133 15.4999 13.7399C15.4999 13.4999 15.3266 13.3066 15.1133 13.3066H14.1133C13.9599 13.3066 13.8399 13.4466 13.8399 13.6199C13.8333 13.8466 13.8733 13.8599 14.0333 13.9133Z"
          fill="#F4F6FF"
        />
      </svg>
    </SvgIcon>
  );
}
