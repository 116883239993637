import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { Link } from "react-router-dom";
import useForm from "src/hooks/useForm";
import SwalCatch from "src/utils/helpers/SwalCatch";
import SwalSuccess from "src/utils/helpers/SwalSuccess";
import c from "./FormStyle";

const LoginForm = () => {
  const { payload, fnOnChange, valid, fnOnInvalid, message, fnIsValid } =
    useForm({
      email: "",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post("/api/password-reset/email", payload)
      .then((res) => {
        if (res.status === 200) {
          SwalSuccess("Check your email");
        }
      })
      .catch((err) => {
        const errors = [{ message: err?.response?.data?.message }];
        SwalCatch(errors);
      });
  };

  return (
    <Box sx={c.forms} component={"form"} onSubmit={handleSubmit} noValidate>
      <Link to="/">
        <Box
          sx={c.formLogo}
          component="img"
          alt="Logo"
          src="/images/logo.svg"
        ></Box>
      </Link>

      <TextField
        sx={c.field}
        id="email"
        label="E-mail"
        variant="outlined"
        name="email"
        onChange={fnOnChange("email")}
        onInvalid={fnOnInvalid("email", "Het e-mail moet ingevuld zijn!")}
        required
        value={payload.email}
        // @ts-ignore
        error={valid.email}
        helperText={message.email}
      />

      <Button sx={c.button} variant="contained" type="submit">
        Wachtwoord opvragen
      </Button>

      <Typography sx={c.caption}>
        Nog niet geregistreerd?{" "}
        <Link to="/register" className="link">
          Registreer je hier
        </Link>
      </Typography>
    </Box>
  );
};

export default LoginForm;
