import { Box, Container, Typography } from "@mui/material";
import HomeCard from "src/components/moleculs/HomeCard";
import c from "./StudiemateriaalStyle";
import { Helmet } from "react-helmet-async";

const Studiemateriaal = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Studiemateriaal </title>
        <meta
          name="description"
          content="Afhankelijk van het studiepakket krijg je toegang tot verschillende onderdelen: verkeersreglementering en -wetgeving, automechanica, wegverkeerswet, wegcode,."
        />
      </Helmet>
      <Container sx={c.wrapperTop}>
        <Typography variant="h2">Studiemateriaal</Typography>
        <Typography variant="body1" sx={c.text}>
          Afhankelijk van het studiepakket krijg je toegang tot verschillende
          onderdelen:
        </Typography>
      </Container>
      <Box sx={c.wrapperBottom}>
        <Container sx={c.container}>
          <Box sx={c.wrapperCards}>
            <HomeCard
              sxs={c.card}
              variant="typeA"
              number="1"
              title="Cursusmateriaal"
            >
              <ul>
                <li>Syllabus automechanica: veilig en zuinig autorijden</li>
                <li>Syllabus verkeersreglementering /wetgeving</li>
                <li>Syllabus methodiek</li>
              </ul>
            </HomeCard>
            <HomeCard
              sxs={c.card}
              variant="typeA"
              number="2"
              title={`Voorbeeld \nSchriftelijke examenvragen`}
            >
              <ul>
                <li>Verkeersreglementering en wetgeving</li>
                <li>Automechanica</li>
              </ul>
            </HomeCard>
            <HomeCard
              sxs={c.card}
              variant="typeA"
              number="3"
              title={`Voorbeeld \nmondelinge examenvragen`}
            >
              <ul>
                <li>Verkeersreglementering en wetgeving</li>
                <li>Automechanica</li>
              </ul>
            </HomeCard>
            <HomeCard
              sxs={c.card}
              variant="typeA"
              number="4"
              title="Presentatie Verkeersreglementering"
            >
              <img
                src="/images/dummy/IMG_5307.png"
                style={{ marginTop: "30px" }}
              />
            </HomeCard>
            <HomeCard
              sxs={c.card}
              variant="typeA"
              number="5"
              title="Documenten"
            >
              <ul>
                <li>Documentatie</li>
                <li>Samenvattingen per rubriek</li>
                <li>Samenvattingen begrippen</li>
              </ul>
            </HomeCard>
            <HomeCard
              sxs={c.card}
              variant="typeA"
              number="6"
              title={`Stage- en \nmodellesdocumenten`}
            >
              <ul>
                <li>Evaluatieformulier praktijk- /theorielesgever</li>
                <li>Evaluatieformulier openbare weg</li>
                <li>Stage- en evaluatieboek</li>
              </ul>
            </HomeCard>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Studiemateriaal;
