import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";

const tagManagerArgs = {
  gtmId: "GTM-5FGHTRW",
};

TagManager.initialize(tagManagerArgs);
// React Facebook Pixel
ReactPixel.init("1275730680025191", null, { debug: false, autoConfig: false });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
