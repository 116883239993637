import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Package
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Package} param1
 */

export default function PackageIcon({ isActive, sxs }) {
  return isActive ? (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1386"
        data-name="Group 1386"
        xmlns="http://www.w3.org/2000/svg"
        width="29.822"
        height="24.851"
        viewBox="0 0 29.822 24.851"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_196"
              data-name="Rectangle 196"
              width="29.822"
              height="24.851"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1386-2" data-name="Group 1386" clipPath="url(#clip-path)">
          <path
            id="Path_452"
            data-name="Path 452"
            d="M29.821,6.012a1.217,1.217,0,0,1-.82.71q-6.765,2.393-13.522,4.81a1.5,1.5,0,0,1-1.07.02Q7.557,9.1.7,6.669A.88.88,0,0,1,.064,5.495.956.956,0,0,1,.7,4.945Q5.865,3.127,11.028,1.3c1.151-.406,2.3-.81,3.451-1.221a1.215,1.215,0,0,1,.85-.006q6.889,2.435,13.781,4.861a1.134,1.134,0,0,1,.711.67Z"
            transform="translate(0 0)"
          />
          <path
            id="Path_453"
            data-name="Path 453"
            d="M46.362,49.271c0,1.394.02,2.758-.013,4.12a1.371,1.371,0,0,1-.6.93A6.256,6.256,0,0,1,43.5,55.45a19.942,19.942,0,0,1-7.62.778A15.667,15.667,0,0,1,30.854,55.1a7.163,7.163,0,0,1-1.365-.815,1.577,1.577,0,0,1-.633-1.37c.014-1.116,0-2.232,0-3.348v-.3c.13.038.229.062.324.1,2.455.872,4.913,1.736,7.363,2.624a3.045,3.045,0,0,0,2.155.008c2.479-.892,4.963-1.768,7.446-2.651.063-.023.128-.041.215-.07"
            transform="translate(-22.718 -38.786)"
          />
          <path
            id="Path_454"
            data-name="Path 454"
            d="M120.619,42.446v2.139c0,1.737,0,3.475,0,5.212a.453.453,0,0,0,.2.408A1.748,1.748,0,0,1,120.794,53a.514.514,0,0,0-.168.353c-.012,1.33,0,2.66-.009,3.989a.874.874,0,1,1-1.744,0c0-1.31,0-2.621,0-3.931a.5.5,0,0,0-.215-.46,1.724,1.724,0,0,1-.008-2.724.534.534,0,0,0,.222-.483c-.006-2.135,0-4.271-.009-6.406a.318.318,0,0,1,.259-.366c.487-.156.966-.338,1.493-.526"
            transform="translate(-92.894 -33.418)"
          />
        </g>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1035"
        data-name="Group 1035"
        xmlns="http://www.w3.org/2000/svg"
        width="30.225"
        height="25.184"
        viewBox="0 0 30.225 25.184"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_161"
              data-name="Rectangle 161"
              width="30.225"
              height="25.184"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1034" data-name="Group 1034" clipPath="url(#clip-path)">
          <path
            id="Path_92"
            data-name="Path 92"
            d="M0,5.676a1.22,1.22,0,0,1,.832-.717q6.906-2.424,13.8-4.87a1.352,1.352,0,0,1,.947,0q6.942,2.454,13.886,4.9c.617.218.882.677.7,1.19a.894.894,0,0,1-.572.555c-.434.157-.866.318-1.3.462a.24.24,0,0,0-.2.271c0,2.744,0,5.488.01,8.233a.348.348,0,0,0,.159.258,2.678,2.678,0,0,1-.07,4.932c-.027.012-.052.027-.1.051,0,1.092,0,2.193,0,3.294a.9.9,0,0,1-.64.919.882.882,0,0,1-1.127-.866q-.013-1.549,0-3.1a.344.344,0,0,0-.236-.369,2.653,2.653,0,0,1-.032-4.8.414.414,0,0,0,.269-.442c-.008-2.449,0-4.9,0-7.347V7.9l-1.8.637C24,8.721,24,8.722,24,9.28c0,1.652-.015,3.305.007,4.957a1.862,1.862,0,0,1-.782,1.6A7.463,7.463,0,0,1,20.67,17a20.554,20.554,0,0,1-7.359.668,16.077,16.077,0,0,1-4.765-1.02,8.816,8.816,0,0,1-1.64-.909,1.632,1.632,0,0,1-.689-1.455c.015-1.741,0-3.482.013-5.223a.418.418,0,0,0-.343-.473C4.2,8,2.52,7.393.832,6.807A1.219,1.219,0,0,1,0,6.089Zm26.667.213c-.143-.055-.233-.092-.324-.124Q20.874,3.834,15.4,1.911a.956.956,0,0,0-.587.005Q9.962,3.609,5.119,5.327c-.5.177-1,.358-1.553.555.138.056.217.092.3.121Q9.374,7.961,14.884,9.911a.814.814,0,0,0,.5-.012q4.735-1.669,9.464-3.356c.59-.21,1.179-.424,1.818-.654M8.011,9.355C8,9.5,7.994,9.6,7.994,9.691c0,1.466,0,2.932-.006,4.4a.438.438,0,0,0,.27.468,17.294,17.294,0,0,0,1.991.791,18.942,18.942,0,0,0,8.764.244,9.563,9.563,0,0,0,2.888-.985.582.582,0,0,0,.339-.583c-.017-1.426-.008-2.853-.008-4.279V9.365c-.15.047-.251.075-.35.11q-3.151,1.119-6.3,2.242a1.311,1.311,0,0,1-.944,0c-1.143-.418-2.294-.819-3.441-1.226L8.011,9.355M28.1,18.416a.888.888,0,0,0-1.777-.013.888.888,0,1,0,1.777.013"
            transform="translate(0 -0.001)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
