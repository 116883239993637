import { Box, Container, Typography } from "@mui/material";
import HomeCard from "src/components/moleculs/HomeCard";
import YtEmbed from "src/components/moleculs/YtEmbed";
import c from "./BrevetRijinstructeurStyle";
import { Helmet } from "react-helmet-async";

const BrevetRijinstructeur = () => {
  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Brevet rijinstructeur </title>
        <meta
          name="description"
          content="Na de opleiding rijschoolinstructeur kan je een brevet van beroepsbekwaamheid behalen. Er bestaan verschillende soorten brevetten om rijles te geven."
        />
      </Helmet>
      <Container sx={c.wrapperTop}>
        <Typography variant="h2">Brevet rijinstructeur</Typography>
        <Typography variant="body1" sx={c.text}>
          Na de opleiding rijschoolinstructeur kan je een brevet van
          beroepsbekwaamheid behalen. Er bestaan verschillende soorten brevetten
          om rijles te geven:
        </Typography>
      </Container>
      <Box sx={c.wrapperBottom}>
        <Container sx={c.container}>
          <Box sx={c.wrapperCards}>
            <HomeCard sxs={c.card} variant="typeB" number="II" title="Brevet">
              <Typography variant="body1" sx={c.content}>
                Praktijklesgever cat. B
              </Typography>
              <YtEmbed url="https://www.youtube.com/watch?v=ur2VqkYHMwc" />
            </HomeCard>
            <HomeCard sxs={c.card} variant="typeB" number="III" title="Brevet">
              <Typography variant="body1" sx={c.content}>
                Theorielesgever
              </Typography>
              <YtEmbed url="https://www.youtube.com/watch?v=nrNboqPtKzs&t=0s" />
            </HomeCard>
            <HomeCard sxs={c.card} variant="typeB" number="IV" title="Brevet">
              <Typography variant="body1" sx={c.content}>
                Praktijklesgever cat. A1, A2, A en AM
              </Typography>
              <YtEmbed url="https://www.youtube.com/watch?v=UjntYpvk5_Q&t=0s" />
            </HomeCard>
            <HomeCard sxs={c.card} variant="typeB" number="V" title="Brevet">
              <Typography variant="body1" sx={c.content}>
                Praktijklesgever cat. BE, C1, C1E, C, CE, D1, D1E, D en D
              </Typography>
              <YtEmbed url="https://www.youtube.com/watch?v=vy1rL6COalU&t=0s" />
            </HomeCard>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BrevetRijinstructeur;
