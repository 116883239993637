import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const TitleMainStyle = AsSxObject({
  wrapper: {
    display: "flex",
    alignItems: "center",
    columnGap: "20px",
    justifyContent: "space-between",
    padding: "32px 0",
    borderBottom: "solid 4px",
    borderBottomColor: g.color.primary,
    "@media(max-width: 1021px)": {
      padding: "16px 0",
    },
    "@media(max-width: 601px)": {
      flexWrap: "wrap",
      gap: "16px",
    },
  },
  link: {
    color: g.color.primary,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
});

export default TitleMainStyle;
