import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Logout
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Logout} param1
 */

export default function LogoutIcon({ isActive, sxs }) {
  return isActive ? (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1037"
        data-name="Group 1037"
        xmlns="http://www.w3.org/2000/svg"
        width="23.069"
        height="25.84"
        viewBox="0 0 23.069 25.84"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_162"
              data-name="Rectangle 162"
              width="23.069"
              height="25.84"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1036" data-name="Group 1036" clipPath="url(#clip-path)">
          <path
            id="Path_93"
            data-name="Path 93"
            d="M15.824,0c.151.043.3.082.453.13a2.707,2.707,0,0,1,1.894,2.636c.017,1.16.008,2.321,0,3.481a.808.808,0,1,1-1.611-.013c0-1.11,0-2.22,0-3.33a1.134,1.134,0,0,0-1.28-1.29H3.858c.118.077.18.121.246.16C5.958,2.845,7.8,3.929,9.67,4.981a2.851,2.851,0,0,1,1.56,2.7c-.013,4.019,0,8.038,0,12.057v.33H15.3A1.13,1.13,0,0,0,16.56,18.8c0-.984-.005-1.968,0-2.951a.8.8,0,1,1,1.607-.042c.013.336.005.673,0,1.009,0,.748.025,1.5-.016,2.244a2.692,2.692,0,0,1-2.776,2.617c-1.286.006-2.573,0-3.859,0h-.3c0,.495.006.963,0,1.432a2.7,2.7,0,0,1-2.063,2.632,2.434,2.434,0,0,1-1.881-.221c-2.015-1.146-4.027-2.3-6.021-3.478A2.571,2.571,0,0,1,0,19.666q0-2.459,0-4.919Q0,8.819,0,2.891A2.707,2.707,0,0,1,1.4.357,6.084,6.084,0,0,1,2.353,0ZM1.681,2.242c-.024.255-.061.468-.062.681q0,8.322,0,16.644c0,.059,0,.118,0,.177a1.1,1.1,0,0,0,.6,1.006c.592.334,1.178.68,1.767,1.02L7.845,24a1.166,1.166,0,0,0,.863.192,1.125,1.125,0,0,0,.9-1.221q0-7.692,0-15.383a1.235,1.235,0,0,0-.681-1.168C7.1,5.371,5.266,4.308,3.434,3.25L1.681,2.242"
            transform="translate(-0.001 0.001)"
          />
          <path
            id="Path_94"
            data-name="Path 94"
            d="M187.519,114.179h-.348q-3.455,0-6.911,0a1.345,1.345,0,0,1-.468-.071.792.792,0,0,1-.481-.9.8.8,0,0,1,.8-.637c1.152,0,2.3,0,3.455,0h3.922c-.065-.082-.1-.134-.142-.177-.344-.346-.691-.688-1.033-1.035a.818.818,0,0,1-.044-1.172.807.807,0,0,1,1.172.022q1.29,1.278,2.568,2.568a.8.8,0,0,1,0,1.19q-1.276,1.292-2.567,2.569a.8.8,0,0,1-1.17.032.813.813,0,0,1,.03-1.172c.382-.389.771-.771,1.211-1.21"
            transform="translate(-167.211 -102.528)"
          />
        </g>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1037"
        data-name="Group 1037"
        xmlns="http://www.w3.org/2000/svg"
        width="23.069"
        height="25.84"
        viewBox="0 0 23.069 25.84"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_162"
              data-name="Rectangle 162"
              width="23.069"
              height="25.84"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1036" data-name="Group 1036" clipPath="url(#clip-path)">
          <path
            id="Path_93"
            data-name="Path 93"
            d="M15.824,0c.151.043.3.082.453.13a2.707,2.707,0,0,1,1.894,2.636c.017,1.16.008,2.321,0,3.481a.808.808,0,1,1-1.611-.013c0-1.11,0-2.22,0-3.33a1.134,1.134,0,0,0-1.28-1.29H3.858c.118.077.18.121.246.16C5.958,2.845,7.8,3.929,9.67,4.981a2.851,2.851,0,0,1,1.56,2.7c-.013,4.019,0,8.038,0,12.057v.33H15.3A1.13,1.13,0,0,0,16.56,18.8c0-.984-.005-1.968,0-2.951a.8.8,0,1,1,1.607-.042c.013.336.005.673,0,1.009,0,.748.025,1.5-.016,2.244a2.692,2.692,0,0,1-2.776,2.617c-1.286.006-2.573,0-3.859,0h-.3c0,.495.006.963,0,1.432a2.7,2.7,0,0,1-2.063,2.632,2.434,2.434,0,0,1-1.881-.221c-2.015-1.146-4.027-2.3-6.021-3.478A2.571,2.571,0,0,1,0,19.666q0-2.459,0-4.919Q0,8.819,0,2.891A2.707,2.707,0,0,1,1.4.357,6.084,6.084,0,0,1,2.353,0ZM1.681,2.242c-.024.255-.061.468-.062.681q0,8.322,0,16.644c0,.059,0,.118,0,.177a1.1,1.1,0,0,0,.6,1.006c.592.334,1.178.68,1.767,1.02L7.845,24a1.166,1.166,0,0,0,.863.192,1.125,1.125,0,0,0,.9-1.221q0-7.692,0-15.383a1.235,1.235,0,0,0-.681-1.168C7.1,5.371,5.266,4.308,3.434,3.25L1.681,2.242"
            transform="translate(-0.001 0.001)"
          />
          <path
            id="Path_94"
            data-name="Path 94"
            d="M187.519,114.179h-.348q-3.455,0-6.911,0a1.345,1.345,0,0,1-.468-.071.792.792,0,0,1-.481-.9.8.8,0,0,1,.8-.637c1.152,0,2.3,0,3.455,0h3.922c-.065-.082-.1-.134-.142-.177-.344-.346-.691-.688-1.033-1.035a.818.818,0,0,1-.044-1.172.807.807,0,0,1,1.172.022q1.29,1.278,2.568,2.568a.8.8,0,0,1,0,1.19q-1.276,1.292-2.567,2.569a.8.8,0,0,1-1.17.032.813.813,0,0,1,.03-1.172c.382-.389.771-.771,1.211-1.21"
            transform="translate(-167.211 -102.528)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
