import { useDispatch } from "react-redux";
import { logout } from "src/hooks/useManageApi";
import { setToken } from "src/store/userAccount";
import Swal from "sweetalert2";
/**
 *
 * @param {array} errors
 * @param {boolean} [isRefresh]
 *
 *
 */
const SwalCatch = (errors, isRefresh = false) => {
  let msg = [];
  errors?.forEach((item) => {
    msg.push(`${item.message}`);
  });

  let isExpired = false;
  msg.filter((item) => {
    isExpired = item == "Token expired.";
  });

  const confBtnText = isRefresh ? "Refresh" : "Back";
  const isCancelButton = isRefresh;

  Swal.fire({
    title: "Error!",
    text: msg.join(" \n"),
    icon: "error",
    showCancelButton: isCancelButton,
    confirmButtonText: confBtnText,
    cancelButtonText: "Back",
  }).then((res) => {
    if (res.isConfirmed) {
      if (isRefresh) {
        if (isExpired) {
          window.localStorage.removeItem("persist:root");
          window.location.href = "/";
        } else {
          window.location.href = "/dashboard";
        }
      } else {
        null;
      }
    }
  });
};

export default SwalCatch;
