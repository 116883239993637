import { CloudDownloadOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import g from "src/utils/MuiStyle/Global";
import c from "./DownloadListStyle";

/**
 *
 * @typedef {object} DownloadList
 * @property {object} [sxs]
 * @property {string} text
 * @property {string} src
 * @param {DownloadList} param1
 */

const DownloadList = ({ sxs, text, src }) => {
  return (
    <Box sx={{ ...c.wrapper, ...sxs }}>
      <Typography variant="body1">{text}</Typography>
      <Button
        variant="contained"
        sx={{ ...g.button.secondary, ...g.button.hasIcon }}
        startIcon={<CloudDownloadOutlined />}
        href={src}
      >
        Download
      </Button>
    </Box>
  );
};

export default DownloadList;
