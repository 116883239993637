import React from "react";
import AuthFooter from "src/components/auth/AuthFooter";
import ResetPasswordComp from "src/components/auth/ResetPasswordComp";

const ResetPasswordPage = () => {
  return (
    <>
      <ResetPasswordComp />
      <AuthFooter />
    </>
  );
};

export default ResetPasswordPage;
