import { AsSxObject } from "src/utils/AsSxObject";

const Styles = AsSxObject({
  wrapper: {
    marginBottom: "130px",
  },
  form: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.11)",
    borderRadius: "40px",
    display: "flex",
    padding: "12px",
    "@media(max-width: 600px)": {
      flexDirection: "column",
      gap: "10px",
      borderRadius: "20px",
    },
  },
  form__icon: {},
  form__close: {
    padding: "0",
    paddingBlock: "0 !important",
    margin: "0",
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "translateY(-2px)",
    "&:hover": {
      boxShadow: "none",
    },
    span: {
      display: "none",
    },
  },
  form__line: {
    width: "2px",
    backgroundColor: "#a7a7a7",
    height: "35px",
    marginBlock: "auto",
    "@media(max-width: 600px)": {
      height: "2px",
      width: "100%",
    },
  },
  form__input: {
    display: "flex",
    alignItems: "center",
    borderRadius: "60px",
    padding: "4px 6px",
    color: "#676767",
    flex: "1",
    gap: "10px",

    ">div": {
      flex: "1",
    },

    "> svg": {
      marginInline: "6px",
    },
    "> div": {
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
    "> button": {
      paddingBlock: "8px",
      whiteSpace: "nowrap",
    },
  },
  form__button: {
    paddingInline: "42px",
  },
  card__wrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "60px",
  },
  card__button: {
    flex: "0 0 100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  card__item: {
    flex: "0 0 50%",
    padding: "16px",
    "@media(max-width: 1024px)": {
      flex: "0 0 100%",
    },
  },
});

export default Styles;
