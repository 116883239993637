import { useRef, useEffect } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { Box } from "@mui/system";
import s from "./RenderedYouTubeStyle";

/**
 * @typedef {object} RenderedYouTubeProps
 * @property {string} [youtubeId]
 *
 * @param {RenderedYouTubeProps} param1
 */

const RenderedYouTube = ({ youtubeId }) => {
  const swiperSlide = useSwiperSlide();
  const iframeRef = useRef(null);

  const isSlideActive = swiperSlide.isActive;

  // Make iframe paused when slide doesn't active
  useEffect(() => {
    const divElement = iframeRef.current;

    if (!isSlideActive) {
      divElement.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
  }, [isSlideActive]);

  return (
    <Box component={"div"} sx={s.embedWrapper}>
      <Box
        ref={iframeRef}
        component={"iframe"}
        sx={s.embedResponsive}
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${youtubeId}?enablejsapi=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Box>
  )
}

export default RenderedYouTube;
