import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const BelevingstrajectStyle = AsSxObject({
  wrapper: {
    marginTop: "64px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "450px",
    gap: "16px",
    "@media(max-width: 600px)": {
      marginTop: "30px",
      paddingInline: "16px",
    },
  },
  fields: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "30px",
  },
  field: {},
  button: {
    width: "fit-content",
  },
  caption: {
    fontSize: "14px",
    color: g.color.primary,
    "*": {
      color: g.color.primary,
    },
  },
});

export default BelevingstrajectStyle;
