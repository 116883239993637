import { AsSxObject } from "src/utils/AsSxObject";

const GaleryLogo = AsSxObject({
  wrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  items: {
    flex: "0 0 25%",
    padding: "20px",
    textAlign: "center",
    "@media(max-width: 600px)": {
      flex: "33.3333%",
      padding: "16px",
    },
  },
  logoImg: {
    objectFit: "contain",
    maxHeight: "100px",
    objectPosition: "center",
    filter: "grayscale(1)",
    transition: "all ease .3s",
    "&:hover": {
      filter: "grayscale(0)",
      transition: "all ease .3s",
    },
  },
});

export default GaleryLogo;
