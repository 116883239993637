import { AsSxObject } from "src/utils/AsSxObject";

const Styles = AsSxObject({
  wrapper: {
    background: "white",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.11)",
    borderRadius: "20px",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  title: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  },
  title__label: {
    fontSize: "20px",
    backgroundColor: (t) => t.palette.primary.main,
    color: "white",
    height: "40px",
    width: "40px",
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title__text: {
    fontSize: "20px",
  },
  content__text: {
    lineHeight: "24px",
    color: "#676767",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footer__button: {
    padding: "8px",
    fontFamily: "Metropolis-Bold",
    fontSize: "16px",
    lineHeight: "1.2em",
    fontStyle: "normal",
    color: (t) => t.palette.primary.main,
    "&:hover": {
      boxShadow: "none",
    },
    svg: {
      marginLeft: "8px",
    },
  },
  footer__button__location: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    paddingInline: 0,
    svg: {
      marginLeft: 0,
      transform: "translateY(-2px)",
    },
  },
});

export default Styles;
