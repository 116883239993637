import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const TitleListStyle = AsSxObject({
  wrapper: {
    display: "flex",
    alignItems: "center",
    columnGap: "20px",
  },
  numberWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "36px",
    minHeight: "42px",
  },
  number: {
    fontSize: "27px",
    fontWeight: "bold",
    textAlign: "left",
    zIndex: 2,
    position: "relative",
    color: g.color.white,
  },
  numberBG: {
    position: "absolute",
    backgroundColor: g.color.primary,
    minWidth: "36px",
    minHeight: "42px",
    width: "100%",
    height: "100%",
    inset: "0",
    zIndex: "0",
    transform: "rotate(-12deg)",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
    marginTop: "0",
  },
});

export default TitleListStyle;
