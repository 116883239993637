import { Box, Typography } from "@mui/material";
import c from "./TitleListStyle";

/**
 *
 * @typedef {object} TitleList
 * @property {object} [sxs]
 * @property {string} number
 * @property {string} text
 * @property {string} [id]
 *
 * @param {TitleList} param1
 */

const TitleList = ({ sxs, number, text, id = "" }) => {
  return (
    <Box sx={{ ...c.wrapper, ...sxs }} id={id}>
      <Box component="div" sx={{ ...c.numberWrapper, ...sxs?.numberWrapper }}>
        <Box sx={{ ...c.numberBG, ...sxs?.numberBG }} />
        <Typography sx={{ ...c.number, ...sxs?.number }}>{number}</Typography>
      </Box>
      <Typography
        variant="h4"
        component="h3"
        sx={{ ...c.title, ...sxs?.title }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TitleList;
