import { Box } from "@mui/material";

/**
 *
 * @param {object} param0
 * @param {any} param0.children
 * @param {object} [ param0.sxs]
 * @param {string} [ param0.className]
 *
 * @returns
 */
const SetInnerHtml = ({ children, sxs, className }) => {
  const xs = {
    paddingInline: "24px",
  };
  return (
    <Box
      component={"div"}
      className={className ?? ""}
      dangerouslySetInnerHTML={{ __html: children }}
      sx={{ ...xs, ...(sxs ? sxs : {}) }}
    />
  );
};

export default SetInnerHtml;
