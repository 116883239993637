import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import c from "./../SlidesStyle";

const Question = ({ item }) => {
  const [answer, setAnswer] = useState(null);
  const [helperText, setHelperText] = useState("");
  const [error, setError] = useState(false);

  const correctAnswer = "correct";

  const handleRadioChange = (event) => {
    setAnswer(event.target.value);
    setHelperText(" ");
    setError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (answer == correctAnswer) {
      setHelperText("Jij hebt het!");
      setError(false);
    } else {
      setHelperText("Sorry, fout antwoord!");
      setError(true);
    }
  };

  return (
    <Box sx={c.questionWrapper}>
      {item.image !== null && (
        <Box
          component={"img"}
          width="100px"
          sx={c.questionImage}
          src={item.image}
        ></Box>
      )}
      <Typography variant="h4">{item.question}</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl sx={{ m: 3 }} error={error} variant="standard">
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="slide-question"
            value={answer}
            onChange={handleRadioChange}
          >
            {item.answer?.map((answer, i) => {
              return (
                <FormControlLabel
                  value={answer.correct ? "correct" : `wrong-${i}`}
                  control={<Radio />}
                  label={answer.awnser}
                />
              );
            })}
          </RadioGroup>
          <FormHelperText>{helperText}</FormHelperText>
          {item.id !== null && (
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "fit-content" }}
            >
              Controleer
            </Button>
          )}
        </FormControl>
      </form>
    </Box>
  );
};

export default Question;
