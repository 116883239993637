import React from "react";
import Blogs from "src/components/frontend/Blogs/Blogs";
import Footer from "src/components/frontend/Footer/Footer";
import Navbar from "src/components/frontend/Navbar/Navbar";
import Title from "src/components/frontend/Title/Title";

const Blog = () => {
  return (
    <>
      <Navbar />
      <Title text="Het laatste nieuws" />
      <Blogs />
      <Footer />
    </>
  );
};

export default Blog;
