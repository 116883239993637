import { AsSxObject } from "src/utils/AsSxObject";

const HomeCarcStyle = AsSxObject({
  wrapper: {
    display: "blog",
  },
  title: {
    color: (t) => t.palette.primary.main,
  },
});

export default HomeCarcStyle;
