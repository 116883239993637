import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Input,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import c from "./FormPopupStyle";
import useForm from "src/hooks/useForm";
import React, { useRef, useState } from "react";
import axios from "axios";
import { URI } from "src/constants/endpoint";
import FormPopupSuccess from "./FormPopupSuccess";

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const AggrementLabel = () => {
  return (
    <>
      Ik ga ermee akkoord om andere berichten te ontvangen van Platform
      Rijschoollesgever. Bekijk onze{" "}
      <a href="/privacy-policy" target="_blank">
        privacy policy
      </a>{" "}
      om je af te melden voor direct marketing.
    </>
  );
};

/**
 * @typedef {object} TFormPopup
 * @property {boolean} isOpen
 * @property {object|boolean} job
 * @property {() => void} handleClose
 *
 * @param {TFormPopup} param0
 * @returns
 */
const FormPopup = ({ isOpen, handleClose, job = false }) => {
  const { payload, fnOnChange, valid, fnOnInvalid, message, fnIsValid } =
    useForm({
      name: "",
      lastName: "",
      email: "",
      tel: "",
      motivation: "",
      aggrement: "",
    });

  const formRef = useRef(null);
  const [cv, setCv] = useState();
  const [fileName, setFilename] = useState("");
  const [buttonSubmit, setButtonSubmit] = useState("Aanmelden");
  const [isfromShow, setIsFormShow] = useState(true);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  const handleChangeCV = (e) => {
    const file = e.target.files[0];
    setCv(file);
    setFilename(file.name);

    const fileSize = file.size ? file.size / 1024 / 1024 : 0;

    if (fileSize > 5) {
      setIsDisabledSubmit(true);
      window.alert("bestanden groter zijn dan 5 MB");
    } else {
      setIsDisabledSubmit(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (payload.aggrement !== true) return;

    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("lastName", payload.lastName);
    formData.append("email", payload.email);
    formData.append("tel", payload.tel);
    formData.append("motivation", payload.motivation);
    formData.append("job_title", job?.title);
    formData.append("job_location", job?.meta?.location);
    formData.append("cv", cv);

    // @ts-ignore
    const fileSize = cv.size ? cv.size / 1024 / 1024 : 0;
    if (!fnIsValid(formRef) || fileSize > 5) return;
    setIsDisabledSubmit(true);

    axios
      .post(URI.submitCandidate, formData, {
        onUploadProgress(progressEvent) {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
              progressEvent.target.getResponseHeader(
                "x-decompressed-content-length"
              );

          if (totalLength !== null) {
            const progressData = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
            const label =
              progressData === 100 ? `Laden` : `Uploaden ${progressData ?? 0}%`;
            setButtonSubmit(label);
          }
        },
      })
      .then(() => {
        setButtonSubmit(`Aanmelden`);
        setIsFormShow(false);
        setIsDisabledSubmit(false);
      });
  };

  return (
    <Dialog
      open={isOpen}
      // @ts-ignore
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={c.popup}
    >
      <DialogContent>
        {isfromShow ? (
          <Box
            sx={c.forms}
            component={"form"}
            onSubmit={handleSubmit}
            noValidate
            ref={formRef}
          >
            <TextField
              sx={c.field}
              id="name"
              label="Naam"
              variant="outlined"
              name="name"
              onChange={fnOnChange("name")}
              onInvalid={fnOnInvalid("name", "Het Naam moet ingevuld zijn!")}
              required
              value={payload.name}
              // @ts-ignore
              error={valid.name}
              helperText={message.name}
            />

            <TextField
              sx={c.field}
              id="lastName"
              label="Achternaam"
              variant="outlined"
              name="lastName"
              onChange={fnOnChange("lastName")}
              onInvalid={fnOnInvalid(
                "lastName",
                "Het Achternaam moet ingevuld zijn!"
              )}
              required
              value={payload.lastName}
              // @ts-ignore
              error={valid.lastName}
              helperText={message.lastName}
            />

            <TextField
              sx={c.field}
              id="email"
              label="Mailadres"
              variant="outlined"
              name="email"
              onChange={fnOnChange("email")}
              onInvalid={fnOnInvalid(
                "email",
                "Het Mailadres moet ingevuld zijn!"
              )}
              required
              value={payload.email}
              // @ts-ignore
              error={valid.email}
              helperText={message.email}
            />

            <TextField
              sx={c.field}
              id="tel"
              type="tel"
              label="Telefoonnumer"
              variant="outlined"
              name="tel"
              onChange={fnOnChange("tel")}
              onInvalid={fnOnInvalid(
                "tel",
                "Het Telefoonnumer moet ingevuld zijn!"
              )}
              required
              value={payload.tel}
              // @ts-ignore
              error={valid.tel}
              helperText={message.tel}
            />

            <TextField
              sx={c.field}
              id="motivation"
              label="Motivatie"
              variant="outlined"
              name="motivation"
              type="te"
              onChange={fnOnChange("motivation")}
              onInvalid={fnOnInvalid(
                "motivation",
                " Het Motivatie moet ingevuld zijn!"
              )}
              multiline
              rows={4}
              required
              value={payload.motivation}
              // @ts-ignore
              error={valid.motivation}
              helperText={message.motivation}
            />

            <Box sx={c.wrapper_cv}>
              <label htmlFor="uploadCV">
                <Button sx={c.button_cv} variant="outlined" component="span">
                  Upload CV
                </Button>
              </label>
              <Typography>{fileName}</Typography>
              <Typography sx={c.wrapper_cv__helper}>Max: 5MB</Typography>
            </Box>

            <Input
              sx={c.field_input_hidden}
              id="uploadCV"
              type="file"
              hidden
              placeholder="Upload CV"
              name="uploadCV"
              // @ts-ignore
              onChange={handleChangeCV}
              onInvalid={fnOnInvalid(
                "uploadCV",
                "Het Upload CV moet ingevuld zijn!"
              )}
              required
            />

            <Typography>
              Platform Rijschoollesgever streeft een gerechtvaardigd belang na
              inzake gegevensbescherming bij de verwerking van jouw
              persoonsgegevens.
            </Typography>

            <FormControlLabel
              sx={c.field}
              control={
                <Checkbox
                  defaultChecked={false}
                  onChange={fnOnChange("aggrement", true)}
                />
              }
              label={<AggrementLabel />}
            />

            <Button
              sx={c.button}
              variant="contained"
              type="submit"
              disabled={isDisabledSubmit}
            >
              {buttonSubmit}
            </Button>
          </Box>
        ) : (
          <FormPopupSuccess />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FormPopup;
