import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questionOpen: null,
  question: null,
};

export const questionOpenSlice = createSlice({
  name: "questionOpen",
  initialState,
  reducers: {
    setQuestionOpen: (state, action) => ({
      ...state,
      questionOpen: action.payload,
    }),
    setQuestion: (state, action) => ({
      ...state,
      question: action.payload,
    }),
    setIsAnsweredQuestionTrue: (state, action) => {
      let open = state.question;

      if (open) {
        open.map((item) => {
          if (item.id === action.payload) {
            item.isAnswered = true;
          }
          return item;
        });
      }
    },
    setIsAnsweredQuestionFalse: (state, action) => {
      let open = state.question;

      if (open) {
        open.map((item) => {
          if (item.id === action.payload) {
            item.isAnswered = false;
          }
          return item;
        });
      }
    },
    setIsAnsweredTrue: (state, action) => {
      let open = state.questionOpen;

      if (open) {
        open.map((item) => {
          if (item.id === action.payload) {
            item.isAnswered = true;
          }
          return item;
        });
      }
    },
    setIsAnsweredFalse: (state, action) => {
      let open = state.questionOpen;

      if (open) {
        open.map((item) => {
          if (item.id === action.payload) {
            item.isAnswered = false;
          }
          return item;
        });
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsAnsweredQuestionTrue,
  setIsAnsweredQuestionFalse,
  setQuestionOpen,
  setQuestion,
  setIsAnsweredFalse,
  setIsAnsweredTrue,
} = questionOpenSlice.actions;

export default questionOpenSlice.reducer;
