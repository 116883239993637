import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Account
 * @property {object} [sxs]
 * @property {boolean} [isActive]
 *
 * @param {Account} param1
 */

export default function AccountIcon({ isActive, sxs }) {
  return isActive ? (
    <SvgIcon sx={sxs}>
      <svg
        id="Group_1377"
        data-name="Group 1377"
        xmlns="http://www.w3.org/2000/svg"
        width="23.072"
        height="24.754"
        viewBox="0 0 23.072 24.754"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_195"
              data-name="Rectangle 195"
              width="23.072"
              height="24.754"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1376" data-name="Group 1376" clipPath="url(#clip-path)">
          <path
            id="Path_450"
            data-name="Path 450"
            d="M.7,76.634a1.066,1.066,0,0,1-.7-1.2A11.269,11.269,0,0,1,.784,71.43a8.216,8.216,0,0,1,4.376-4.42,13.742,13.742,0,0,1,4.576-1.1,20.5,20.5,0,0,1,4.436.089,11.55,11.55,0,0,1,5.285,1.9,8.1,8.1,0,0,1,3.33,5.2c.156.769.184,1.564.272,2.347a1.019,1.019,0,0,1-.7,1.2Z"
            transform="translate(0 -51.88)"
          />
          <path
            id="Path_451"
            data-name="Path 451"
            d="M26.132,6.138A6.023,6.023,0,0,1,28.63,1.1,5.862,5.862,0,0,1,32.961.061a5.652,5.652,0,0,1,4.1,2.5,6.239,6.239,0,0,1,1.008,4.4,6.641,6.641,0,0,1-2.015,4.07,5.633,5.633,0,0,1-7.418.359,6.68,6.68,0,0,1-2.5-5.245"
            transform="translate(-20.596 0)"
          />
        </g>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={sxs}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.07"
        height="24.752"
        viewBox="0 0 23.07 24.752"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_159"
              data-name="Rectangle 159"
              width="23.07"
              height="24.752"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_1031" data-name="Group 1031" transform="translate(0 -1)">
          <g
            id="Group_1030"
            data-name="Group 1030"
            transform="translate(0 1)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_88"
              data-name="Path 88"
              d="M.7,241.113a1.066,1.066,0,0,1-.7-1.2,11.268,11.268,0,0,1,.783-4,8.216,8.216,0,0,1,4.376-4.42,13.743,13.743,0,0,1,4.576-1.1,20.5,20.5,0,0,1,4.436.089,11.549,11.549,0,0,1,5.285,1.9,8.094,8.094,0,0,1,3.33,5.195c.156.769.184,1.564.272,2.346a1.019,1.019,0,0,1-.7,1.2Zm1.11-1.82H21.193a.393.393,0,0,0,.014-.086c-.017-.184-.031-.369-.056-.552a7.382,7.382,0,0,0-1.166-3.214,7.007,7.007,0,0,0-3.787-2.687,13.192,13.192,0,0,0-3.365-.6,16.194,16.194,0,0,0-6.063.63,7.348,7.348,0,0,0-2.956,1.765,6.475,6.475,0,0,0-1.69,3.043c-.14.549-.207,1.117-.31,1.7"
              transform="translate(0 -216.361)"
            />
            <path
              id="Path_89"
              data-name="Path 89"
              d="M91.43,6.137a6.022,6.022,0,0,1,2.5-5.038A5.861,5.861,0,0,1,98.259.061a5.651,5.651,0,0,1,4.1,2.5,6.239,6.239,0,0,1,1.008,4.4,6.642,6.642,0,0,1-2.014,4.07,5.632,5.632,0,0,1-7.418.359,6.68,6.68,0,0,1-2.5-5.245m10.183.116a4.424,4.424,0,0,0-1.014-3,4.051,4.051,0,0,0-3.746-1.4,3.821,3.821,0,0,0-2.979,1.907,4.949,4.949,0,0,0,.9,5.982,3.793,3.793,0,0,0,4.859.354,4.689,4.689,0,0,0,1.982-3.839"
              transform="translate(-85.894 0)"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
