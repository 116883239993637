const { AsSxObject } = require("src/utils/AsSxObject");

const TabsSectionStyle = AsSxObject({
  nav: {
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    paddingInline: "52px",
    borderRadius: "20px",
    boxShadow: "0px 3px 6px #00000029",
    ".MuiTabs-flexContainer": {
      display: "flex",
      "@media(min-width: 1280px)": {
        justifyContent: "space-evenly",
      },
    },
    ".MuiTabs-indicator": {
      height: "4px",
      backgroundColor: (t) => t.palette.primary.main,
    },
    "@media(max-width: 1020px)": {
      paddingInline: "16px",
    },
  },
  navItem: {
    fontSize: "18px",
    textTransform: "none",
    color: "#676767",
    fontFamily: "Metropolis-Medium",
    fontWeight: "500",
    height: "90px",
    "&.Mui-selected": {
      color: "#000000",
      fontFamily: "Metropolis-Bold",
      fontWeight: "bold",
    },
  },
  wrapper: {
    width: "100%",
    position: "relative",
  },
  socialMedia: {
    position: "sticky",
    bottom: "30px",
    flexDirection: "column",
    alignItems: "end",
    paddingRight: "30px",
    gap: "16px",
    maxHeight: "auto",
    marginBottom: "30px",
    width: "fit-content",
    marginLeft: "auto",
  },
});

export default TabsSectionStyle;
