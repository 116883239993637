import { Box, Button, Container, Input } from "@mui/material";
import c from "./VacaturesStyle";
import Card from "./Card";
import IcSearch from "src/components/atoms/icons/Svg/IcSearch";
import IcLocation from "src/components/atoms/icons/Svg/IcLocation";
import { useEffect, useState } from "react";
import axios from "axios";
import IcClose from "src/components/atoms/icons/Svg/IcClose";
import ErrorPage from "src/components/moleculs/ErrorPage/ErrorPage";

const Vacatures = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
    location: "",
  });

  useEffect(() => {
    axios.get(`/api/vacatures`).then((res) => {
      setData(res.data);
    });
  }, []);

  const handleChange = (name) => (e) => {
    e.preventDefault();

    setFilter((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleResetField = (name) => (e) => {
    e.preventDefault();
    setFilter((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.get(`/api/vacatures`, { params: filter }).then((res) => {
      setData(res.data);
    });
  };

  const handleClickPagination = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const nextPage = data ? data.current_page + 1 : 1;

    axios
      .get(`/api/vacatures`, { params: { ...filter, page: nextPage } })
      .then((res) => {
        setData((prev) => ({
          ...prev,
          current_page: res.data.current_page,
          offset: res.data.offset,
          items: [...prev?.items, ...res.data.items],
        }));
        setIsLoading(false);
      });
  };

  return (
    <Box sx={c.wrapper}>
      <Box component={"section"} id="VacaturesFilter">
        <Container>
          <Box component={"form"} sx={c.form} onSubmit={handleSubmit}>
            <Box className={"FormField"} sx={c.form__input}>
              <IcSearch />
              <Input
                placeholder="Zoek een functie"
                onChange={handleChange("search")}
                value={filter.search}
              />
              {filter.search && (
                <Button sx={c.form__close} onClick={handleResetField("search")}>
                  <IcClose />
                </Button>
              )}
            </Box>
            <Box component={"span"} sx={c.form__line} />
            <Box className={"FormField"} sx={c.form__input}>
              <IcLocation />
              <Input
                placeholder="Locatie"
                onChange={handleChange("location")}
                value={filter.location}
              />
              {filter.location && (
                <Button
                  sx={c.form__close}
                  onClick={handleResetField("location")}
                >
                  <IcClose />
                </Button>
              )}
            </Box>
            <Button sx={c.form__button} type="submit" variant="contained">
              Zoeken
            </Button>
          </Box>
        </Container>
      </Box>
      <Box component={"section"} id="VacaturesCard">
        <Container>
          <Box sx={c.card__wrapper}>
            {data && data.items.length > 0 ? (
              data.items.map((item) => {
                return (
                  <Box key={item.id} sx={c.card__item}>
                    <Card
                      slug={item.slug}
                      title={item.title}
                      desc={item.sort_desc}
                      location={item.location}
                    />
                  </Box>
                );
              })
            ) : (
              <ErrorPage title="Geen vacature gevonden" />
            )}
          </Box>
          {data?.current_page < data?.total_page ? (
            <Box sx={c.card__button}>
              <Button
                variant="contained"
                onClick={handleClickPagination}
                disabled={isLoading}
              >
                {isLoading ? "Even geduld" : "Bekijk meer vacature"}
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Vacatures;
