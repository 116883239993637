import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml";
import c from "./FooterBoxStyle";

/**
 * @typedef {object} FooterBox
 * @property {string} [title]
 * @property {string} [subtitle]
 * @property {string} [text]
 * @property {string} [src]
 * @property {string} variant
 * @property {any} [children]
 *
 * @param {FooterBox} props
 *
 */

export default function FooterBox(props) {
  const { title, subtitle, text, src, children, variant } = props;

  switch (variant) {
    case "logo":
      return (
        <Box
          sx={{ ...c.item, ...c.logo }}
          className="FooterBox FooterBox--Logo"
        >
          <Box
            component="img"
            src={src}
            alt="Logo"
            sx={c.logoImg}
            height={50}
            width={100}
          />
        </Box>
      );
    case "full":
      return (
        <Box sx={c.item} className="FooterBox FooterBox--Full">
          <Typography variant="body2" sx={c.title}>
            {title}
          </Typography>
          <Typography variant="body2" sx={c.subtitle}>
            {subtitle}
          </Typography>
          <Typography component={"div"} variant="body2" sx={c.text}>
            <SetInnerHtml children={text} sxs={{ paddingLeft: "0" }} />
          </Typography>
        </Box>
      );
    case "notitle":
      return (
        <Box sx={c.item} className="FooterBox FooterBox--NoTitle">
          <Typography variant="body2" sx={c.subtitle}>
            {subtitle}
          </Typography>
          <Typography component={"div"} variant="body2" sx={c.text}>
            <SetInnerHtml children={text} sxs={{ paddingLeft: "0" }} />
          </Typography>
        </Box>
      );
    case "special":
      return (
        <Box sx={c.item} className="FooterBox FooterBox--Special">
          <Typography variant="body2" sx={{ ...c.title, ...c.spesialTitle }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={c.subtitle}>
            {subtitle}
          </Typography>
          <Typography component={"div"} variant="body2" sx={c.text}>
            <SetInnerHtml children={text} sxs={{ paddingLeft: "0" }} />
          </Typography>
        </Box>
      );
    case "nosubtitle":
      return (
        <Box sx={c.item} className="FooterBox FooterBox--NoSubtitle">
          <Typography variant="body2" sx={c.title}>
            {title}
          </Typography>
          <Typography component={"div"} variant="body2" sx={c.text}>
            <SetInnerHtml
              children={text}
              sxs={{
                paddingLeft: "0",
                "@media(min-width: 1025px)": { whiteSpace: "pre" },
                a: { textDecoration: "underline" },
              }}
            />
          </Typography>
        </Box>
      );
    case "icon":
      return (
        <Box
          sx={{ ...c.item, ...c.itemIcon }}
          className="FooterBox FooterBox--Icon"
        >
          <Typography variant="body2" sx={c.title}>
            {title}
          </Typography>
          <Box>{children}</Box>
        </Box>
      );
    case "fullwidth":
      return (
        <Box
          sx={{ ...c.item, ...c.fullwidth }}
          className="FooterBox FooterBox--Fullwidth"
        >
          <Typography variant="body2" sx={c.title}>
            {title}
          </Typography>
        </Box>
      );

    default:
      break;
  }
}
