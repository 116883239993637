import { useRef, useEffect } from 'react';
import { Box } from "@mui/system";
import { useSwiperSlide } from "swiper/react";
import s from "./RenderedVideoStyle";

/**
 * @typedef {object} RenderedVideoProps
 * @property {string} [url]
 *
 * @param {RenderedVideoProps} param1
 */

const RenderedVideo = ({ url }) => {
  const swiperSlide = useSwiperSlide();
  const iframeRef = useRef(null);

  const isSlideActive = swiperSlide.isActive;

  useEffect(() => {
    const video = iframeRef.current;

    if (!isSlideActive) {
      video.pause();
    };
  }, [isSlideActive]);

  return (
    <Box
      component={"div"}
      sx={s.videoWrapper}
    >
      <Box ref={iframeRef} component={"video"} sx={s.videoSource} controls>

        <Box component={"source"} src={url} type="video/mp4" />
        Your browser does not support the video tag.

      </Box>
    </Box>
  )
}

export default RenderedVideo;
