import { AsSxObject } from "src/utils/AsSxObject";

const LoginStyle = AsSxObject({
  wrapper: {
    display: "flex",
    "@media(max-width: 1020px)": {
      justifyContent: "center",
      alignItems: "center",
      marginBlock: "auto",
      minHeight: "100vh",
    },
  },
  formWrapper: {
    flex: "0 0 55%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  img: {
    flex: "0 0 45%",
    objectFit: "cover",
    maxHeight: "100vh",
    "@media(max-width: 1020px)": {
      display: "none",
    },
  },
});

export default LoginStyle;
