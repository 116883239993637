const { AsSxObject } = require("src/utils/AsSxObject");

const DocumentStyle = AsSxObject({
  wrapper: {
    marginTop: "40px",
  },
  wrapperList: {
    marginTop: "40px",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "16px",
    "@media(min-width: 1280px)": {
      minHeight: "500px",
      position: "relative",
    },
  },
  subWrapperList: {
    flex: "0 0 50%",
    columnGap: "86px",
    rowGap: "30px",
    display: "flex",
    flexWrap: "wrap",
    "@media(max-width: 1020px)": {
      columnGap: "30px",
    },
    "@media(max-width: 600px)": {
      rowGap: "16px",
    },
  },
  downloadItem: {
    flex: "0 0 calc(100% - (86px * 1 /2))",
    "@media(max-width: 1020px)": {
      flex: "0 0 calc(100% - (30px * 1 /2))",
    },
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
});

export default DocumentStyle;
