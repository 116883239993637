import { Box, Button, Typography } from "@mui/material";
import c from "./ModellesStyle";
import TitleMain from "src/components/atoms/TitleMain";
import WrapperContent from "src/components/moleculs/WrapperContent";
import DownloadList from "src/components/moleculs/DownloadList";
import ExternalLink from "src/components/atoms/ExternalLink";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Loading from "src/components/atoms/Loading";

const Modelles = () => {
  const [datas, setDatas] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoad(true);

    if (mounted) {
      axios.get("/api/modelles-document").then((res) => {
        setIsLoad(false);
        setDatas(res.data.items);
      });
    }

    return () => {
      setIsLoad(false);
      mounted = false;
    };
  }, []);

  return (
    <WrapperContent isLight>
      {isLoad ? (
        <Loading sxs={{ position: "absolute", background: "transparent" }} />
      ) : (
        <Box sx={c.wrapper}>
          <TitleMain text="Stage- en modellesdocumenten" />
          <Typography variant="body1" sx={c.desc}>
            Hieronder vind je documenten terug ter voorbereiding van jouw stage
            en modelles. Dit zijn onder andere aanvraagformulieren,
            evaluatieformulieren, attesten en inlichtingen documenten.
          </Typography>
          <ExternalLink href="mailto:info@platform-rijschoollesgever.be?subject=Ondersteuning Modellessen">
            <Button variant="contained">Ondersteuning modellessen</Button>
          </ExternalLink>
          <Box sx={c.wrapperListParent}>
            {datas?.map((data, i) => {
              return (
                <Box key={`${data.title}-${i}`} sx={c.wrapperListGroup}>
                  <Typography variant="h4">{data.title}</Typography>
                  <Box sx={c.wrapperList}>
                    {data.docs?.map((doc, k) => {
                      return (
                        <DownloadList
                          key={`${doc.file}-${k}`}
                          sxs={c.downloadItem}
                          text={doc.title}
                          src={doc.file}
                        />
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </WrapperContent>
  );
};

export default Modelles;
