import { Box } from "@mui/system";
import s from "./RenderedImageStyle";

/**
 * @typedef {object} RenderedImageProps
 * @property {string} [src]
 *
 * @param {RenderedImageProps} param1
 */

const RenderedImage = ({ src }) => {
  return (
    <Box
      component={"img"}
      width='100px'
      sx={s.slideImage}
      src={src}
    />
  );
};

export default RenderedImage;
