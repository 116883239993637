import { AsSxObject } from "src/utils/AsSxObject";
import g from "src/utils/MuiStyle/Global";

const RegisterStyle = AsSxObject({
  wrapper: {
    display: "flex",
    "@media(max-width: 1020px)": {
      justifyContent: "center",
      alignItems: "center",
      marginBlock: "auto",
      minHeight: "100vh",
    },
  },
  formWrapper: {
    flex: "0 0 55%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBlock: "60px",
    flexDirection: "column",
    "@media(max-width: 600px)": {
      flex: "0 0 100%",
    },
  },
  formLogo: {
    height: "100px",
    objectFit: "contain",
    marginBottom: "60px",
    "@media(max-width: 600px)": {
      height: "50px",
      marginBottom: "16px",
      objectPosition: "left",
    },
  },
  forms: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    width: "100%",
    gap: "16px",
    "@media(max-width: 600px)": {
      paddingInline: "24px",
    },
  },
  field: {
    width: "85%",
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
  button: {
    width: "60%",
    marginTop: "60px",
    "@media(max-width: 600px)": {
      marginTop: "12px",
    },
  },
  caption: {
    fontSize: "12px",
    fontFamily: "Metropolis-medium",
  },
  img: {
    flex: "0 0 45%",
    objectFit: "cover",
    minHeight: "100vh",
    maxHeight: "1080px",
    height: "100%",
    "@media(max-width: 1020px)": {
      display: "none",
    },
  },
  tabs: {
    backgroundColor: g.color.neutral[200],
    borderRadius: "10px",
    "@media(max-width: 600px)": {
      maxWidth: "320px",
      marginTop: "50px",
    },
    ".MuiButtonBase-root": {
      textTransform: "none",
      color: g.color.primary,
      minWidth: "150px",
    },
    ".Mui-selected.Mui-selected.Mui-selected": {
      color: g.color.white,
      backgroundColor: g.color.primary,
      borderRadius: "10px",
      transition: "all .3s ease-in-out",
    },
    ".MuiTabs-indicator": {
      display: "none",
    },
  },
  logo: {
    position: "fixed",
    width: "42px",
    height: "42px",
    objectFit: "contain",
    top: "30px",
    left: "30px",
  },
});

export default RegisterStyle;
