import { ChevronRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import c from "./ButtonListStyle";

/**
 * @typedef {object} ButtonListProps
 * @property {string} text
 * @property {string} [to]
 * @property {object} [sxs]
 *
 * @param {ButtonListProps} param1
 */

const ButtonList = ({ text, to, sxs }) => {
  return (
    <Box sx={{ ...c.wrapper, ...sxs }}>
      <Typography variant="h4" sx={{ ...c.text, ...sxs?.text }}>
        {text}
      </Typography>
      <ChevronRight sx={{ ...c.icon, ...sxs?.icon }} />
      {to && <Link to={"/"} className="streched-link"></Link>}
    </Box>
  );
};

export default ButtonList;
