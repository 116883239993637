import { Box } from "@mui/material";
import Form from "./Form";
import c from "./ResetPasswordStyle";

const ResetPassword = () => {
  return (
    <Box sx={c.wrapper}>
      <Box sx={c.formWrapper}>
        <Form />
      </Box>
      <Box
        sx={c.img}
        component="img"
        alt="Background Login"
        src="/images/dummy/bg-login.jpg"
      ></Box>
    </Box>
  );
};

export default ResetPassword;
