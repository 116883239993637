import { Box } from "@mui/system";
import SetInnerHtml from "src/components/moleculs/SetInnerHtml";
import RenderedImage from 'src/components/atoms/RenderedImage';
import RenderedVideo from 'src/components/atoms/RenderedVideo';
import RenderedYouTube from 'src/components/atoms/RenderedYouTube';
import c from "./../SlidesStyle";

// Get the id of youtube video
const getYouTubeId = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length==11)? match[7] : false;
}

const RenderedMedia = ({ data }) => {
  if (data?.type === "video") {
    {/* This will be rendered Video HTML5 */}
    return (
      <RenderedVideo
        url={data?.video}
      />
    )
  } else if (data?.type === "youtube") {
    {/* This will be rendered Embed YouTube */}
    return (
      // @ts-ignore
      <RenderedYouTube youtubeId={getYouTubeId(data?.youtube)} />
    )
  }

  {/* This will be rendered Image HTML */}
  return (
    <RenderedImage src={data?.image} />
  )
}

const SlidePopup = ({ item }) => {
  return (
    <Box sx={c.questionWrapper}>

      {/* This will be rendered media such as Image, Video or YouTube */}
      <RenderedMedia data={item} />

      <SetInnerHtml sxs={{ paddingInline: "0" }}>{item?.caption}</SetInnerHtml>

    </Box>
  );
};

export default SlidePopup;
