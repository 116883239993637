import g from "src/utils/MuiStyle/Global";
const { AsSxObject } = require("src/utils/AsSxObject");

const WrapperContentStyle = AsSxObject({
  wrapperSm: {
    marginLeft: "360px",
  },
  wrapper: {
    position: "relative",
    marginLeft: "250px",
    padding: "52px 32px",
    minHeight: "100vh",
    "@media(max-width: 1020px)": {
      marginLeft: "0",
      padding: "24px 16px",
    },
    backgroundColor: g.color.white,
  },
  light: {
    backgroundColor: "#f7f7f7",
  },
  container: {
    maxWidth: "1288px",
    marginInline: "auto",
  },
});

export default WrapperContentStyle;
