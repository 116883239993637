import { Box, Button, Container, Input, Typography } from "@mui/material";
import Card from "./Card/Card";
import c from "./BlogsStyle";
import CategoryItem from "./CategoryItem";
import IcSearch from "src/components/atoms/icons/Svg/IcSearch";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Loading from "src/components/atoms/Loading/Loading";
import ErrorPage from "src/components/moleculs/ErrorPage/ErrorPage";

const Blogs = () => {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterType, setFilterType] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios.get("/api/blogs").then((res) => {
      setData(res.data);
      setFilterType(null);
      setIsLoading(false);
    });
  }, []);

  const handleCategoryClick = (slug) => (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios.get(`/api/blogs?category=${slug}`).then((res) => {
      setFilterType({ category: slug });
      setData(res.data);
      setIsLoading(false);
    });
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios.get(`/api/blogs?search=${search}`).then((res) => {
      setData(res.data);
      setFilterType({ search: search });
      setIsLoading(false);
    });
  };

  const handleClickPagination = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const param = filterType
      ? `&${Object.keys(filterType)[0]}=${Object.values(filterType)[0]}`
      : "";
    const nextPage = data ? data.current_page + 1 : 1;

    axios.get(`/api/blogs?page=${nextPage}${param}`).then((res) => {
      setData((prev) => ({
        ...prev,
        current_page: res.data.current_page,
        offset: res.data.offset,
        items: [...prev?.items, ...res.data.items],
      }));
      setIsLoading(false);
    });
  };

  return (
    <>
      <Container>
        <Box sx={c.top__wrapper}>
          <Box sx={c.top__col}>
            <Typography variant="h5" component={"span"}>
              Category:
            </Typography>
            <Box sx={c.top__listscat}>
              {data?.categories.map((category) => {
                return (
                  <CategoryItem
                    key={category.id}
                    name={category.name}
                    isActive={category.is_active}
                    onClick={handleCategoryClick(category.slug)}
                  />
                );
              })}
            </Box>
          </Box>
          <Box
            component={"form"}
            onSubmit={handleSubmitSearch}
            sx={c.top__input}
          >
            <IcSearch />
            <Input
              placeholder="Zoek artikel"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button type="submit" variant="contained">
              Zoeken
            </Button>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box sx={c.wrapper}>
          {data && data.items.length > 0 ? (
            data.items.map((item) => {
              return (
                <Box key={item.id} sx={c.item}>
                  <Card
                    title={item.title}
                    slug={item.slug}
                    published={item.published}
                    category={item.category}
                    desc={item.sort_desc}
                    image={item.image}
                  />
                </Box>
              );
            })
          ) : (
            <ErrorPage title="Geen artikel gevonden" />
          )}
          {data?.current_page < data?.total_page ? (
            <Box sx={c.button}>
              <Button
                variant="contained"
                onClick={handleClickPagination}
                disabled={isLoading}
              >
                {isLoading ? "Even geduld" : "Bekijk meer artikelen"}
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Container>
    </>
  );
};

export default Blogs;
