import { Box } from "@mui/material";
import LoginForm from "./LoginForm";
import c from "./LoginStyle";

const Login = () => {
  return (
    <Box sx={c.wrapper}>
      <Box sx={c.formWrapper}>
        <LoginForm />
      </Box>
      <Box
        sx={c.img}
        component="img"
        alt="Background Login"
        src="/images/dummy/bg-login.jpg"
      ></Box>
    </Box>
  );
};

export default Login;
