import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SetInnerHtml from "../SetInnerHtml/SetInnerHtml.jsx";
import c from "./StaticContentStyle.js";

const StaticContent = ({ children }) => {
  return (
    <Box sx={c.wrapper}>
      <Container>
        <Typography>{children}</Typography>
      </Container>
    </Box>
  );
};

export default StaticContent;
