import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useRef } from "react";
import { Link } from "react-router-dom";
import useForm from "src/hooks/useForm";
import { useDispatch } from "react-redux";
import useMailto from "src/hooks/useMailto";
import { setData } from "src/store/profileAccount";
import { setToken } from "src/store/userAccount";
import SwalCatch from "src/utils/helpers/SwalCatch";
import SwalSuccess from "src/utils/helpers/SwalSuccess";
import c from "./ZelfstudieStyle";

const Zelfstudie = () => {
  const formRef = useRef(null);
  const { payload, fnOnChange, valid, fnOnInvalid, message, fnIsValid } =
    useForm({
      package: "",
      name: "",
      firstname: "",
      email: "",
      password: "",
      repeatPassword: "",
      aggrement: false,
    });
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    // const bodyEmail = {
    //   ["Pakketten"]: payload.package,
    //   ["Naam"]: payload.name,
    //   ["Voornaam"]: payload.firstname,
    //   ["E-mail"]: payload.email,
    // };

    const bodyApi = {
      email: payload.email,
      password: payload.password,
    };

    if (fnIsValid(formRef)) {
      // useMailto("karin@platform-rijschoollesgever.be", "Zelfstudie", bodyEmail);
      if (payload.password === payload.repeatPassword) {
        axios
          .post("/api/register", bodyApi)
          .then((res) => {
            if (res.status === 200) {
              const userToken = res.data.access_token;
              dispatch(setToken(userToken));

              axios
                .get("/api/user", {
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                })
                .then((res) => {
                  const data = res.data.date;
                  dispatch(setData(data));

                  if (payload.package === "Compleet pakket") {
                    window.open(
                      "https://buy.stripe.com/5kA4iV1EKgIte5OcMQ",
                      "_blank"
                    );
                  } else if (payload.package === "Plus pakket") {
                    window.open(
                      "https://buy.stripe.com/8wM9Df1EKcsdf9SbIO",
                      "_blank"
                    );
                  }
                })
                .catch((err) => console.log(err));
            }
          })
          .catch((err) => {
            const errors =
              err?.response?.data?.errors?.email ??
              err?.response?.data?.errors?.password;
            SwalCatch([{ message: errors[0] }]);
          });
      } else {
        SwalCatch([{ message: "Wachtwoord komt niet overeen" }]);
      }
    }
  };
  return (
    <Box sx={c.wrapper}>
      <Box
        sx={c.fields}
        component={"form"}
        onSubmit={handleSubmit}
        ref={formRef}
        noValidate
      >
        <Typography variant="body1">
          Schrijf je in voor <b>één van de drie lespakketten</b>* voor de
          opleiding rijinstructeur.
        </Typography>
        <TextField
          select
          id="package"
          label="Pakketten"
          value={payload.package}
          onChange={fnOnChange("package")}
          onInvalid={fnOnInvalid(
            "package",
            "Het kies pakket moet ingevuld zijn!"
          )}
          required
          // @ts-ignore
          error={valid.package}
          helperText={message.package}
        >
          <MenuItem value={"Basis pakket"}>Basis pakket (gratis)</MenuItem>
          <MenuItem value={"Plus pakket"}>Plus pakket (€ 75)</MenuItem>
          <MenuItem value={"Compleet pakket"}>Compleet pakket (€150)</MenuItem>
          {/* <MenuItem value={30}>Thirty</MenuItem> */}
        </TextField>

        <Link to="/#prijs-rijinstructeur-lespakketten">
          <Typography variant="body1" sx={c.caption}>
            * Bekijk lespakketten hier
          </Typography>
        </Link>

        <TextField
          sx={c.field}
          id="name"
          name="name"
          label="Naam"
          variant="outlined"
          onChange={fnOnChange("name")}
          value={payload.name}
          onInvalid={fnOnInvalid("name", "Het naam moet ingevuld zijn!")}
          required
          // @ts-ignore
          error={valid.name}
          helperText={message.name}
        />
        <TextField
          sx={c.field}
          id="firstname"
          name="firstname"
          label="Voornaam"
          variant="outlined"
          onChange={fnOnChange("firstname")}
          value={payload.firstname}
          onInvalid={fnOnInvalid(
            "firstname",
            "Het voornaam moet ingevuld zijn!"
          )}
          required
          // @ts-ignore
          error={valid.firstname}
          helperText={message.firstname}
        />
        <TextField
          sx={c.field}
          id="email"
          name="email"
          label="E-mail"
          variant="outlined"
          onChange={fnOnChange("email")}
          value={payload.email}
          onInvalid={fnOnInvalid("email", "Het e-mail moet ingevuld zijn!")}
          required
          // @ts-ignore
          error={valid.email}
          helperText={message.email}
        />

        <TextField
          sx={c.field}
          id="password"
          label="Wachtwoord"
          variant="outlined"
          name="password"
          type="password"
          onChange={fnOnChange("password")}
          onInvalid={fnOnInvalid(
            "password",
            " Het wachtwoord moet ingevuld zijn!"
          )}
          required
          value={payload.password}
          // @ts-ignore
          error={valid.password}
          helperText={message.password}
        />

        <TextField
          sx={c.field}
          id="repeatPassword"
          label="Herhaal Wachtwoord"
          variant="outlined"
          name="repeatPassword"
          type="password"
          onChange={fnOnChange("repeatPassword")}
          onInvalid={fnOnInvalid(
            "password",
            " Het wachtwoord moet ingevuld zijn!"
          )}
          required
          value={payload.repeatPassword}
          // @ts-ignore
          error={valid.repeatPassword}
          helperText={message.repeatPassword}
        />

        <FormControlLabel
          sx={c.field}
          control={
            <Checkbox
              defaultChecked={false}
              onChange={fnOnChange("aggrement", true)}
            />
          }
          label="Jullie mogen mijn gegevens bijhouden voor verdere communicatie *"
        />

        <Button sx={c.button} type="submit" variant="contained">
          Pakket aankopen
        </Button>
      </Box>
    </Box>
  );
};

export default Zelfstudie;
