import { SvgIcon } from "@mui/material";

/**
 *
 * @typedef {object} Home
 * @property {object} [sxs]
 *
 * @param {Home} param1
 */

export default function IcWorkHourLabel({ sxs }) {
  return (
    <SvgIcon sx={sxs}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#2946D6" />
        <path
          d="M15.9999 9.33325C12.3266 9.33325 9.33325 12.3266 9.33325 15.9999C9.33325 19.6733 12.3266 22.6666 15.9999 22.6666C19.6733 22.6666 22.6666 19.6733 22.6666 15.9999C22.6666 12.3266 19.6733 9.33325 15.9999 9.33325ZM18.8999 18.3799C18.8066 18.5399 18.6399 18.6266 18.4666 18.6266C18.3799 18.6266 18.2933 18.6066 18.2133 18.5533L16.1466 17.3199C15.6333 17.0133 15.2533 16.3399 15.2533 15.7466V13.0133C15.2533 12.7399 15.4799 12.5133 15.7533 12.5133C16.0266 12.5133 16.2533 12.7399 16.2533 13.0133V15.7466C16.2533 15.9866 16.4533 16.3399 16.6599 16.4599L18.7266 17.6933C18.9666 17.8333 19.0466 18.1399 18.8999 18.3799Z"
          fill="#F4F6FF"
        />
      </svg>
    </SvgIcon>
  );
}
